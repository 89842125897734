import React, { useEffect, useState } from 'react';
import ScenarioEasyStart from './scenarios/ScenarioEasyStart/ScenarioEasyStart';
import ScenarioTestCombustion from './scenarios/ScenarioTestCombustion/ScenarioTestCombustion';
import styles from '../../../styles/RemoteControl.module.css';
import {  AiOutlineInfoCircle } from 'react-icons/ai';

import { Scenarios } from '../../../shared/types';
import Alert from '../../../shared/components/Alert';
import ScenarioMenu from './scenarios/ScenarioMenu/ScenarioMenu';

interface RemoteControlProps {
  selectedScenario: Scenarios;
  setSelectedScenario: React.Dispatch<React.SetStateAction<Scenarios | null>>
}

const RemoteControlNaema: React.FC<RemoteControlProps> = ({
  selectedScenario,
  setSelectedScenario
}) => {
   
  const [showAstuce, setShowAstuce] = useState(false);
  const [showAlert, setShowAlert] = useState(true);
  const [alertMessage, setAlertMessage] = useState('');
  const [showAnimation, setShowAnimation] = useState(true);
  const [seed, setSeed] = useState(1);


  // activate/inactivate
  // const [Buttons, setButtons] = useState<boolean | null>(true);
  const [activeButtons, setActiveButtons] = useState<string[]>([]); // Array to hold the names of enabled buttons

  const [clickedButton, setClickedButton] = useState<string | null>(null);


// ************************** ALERT MESSAGE *****************************************

  const handleAlertClick = () => {
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

// ************************** HANDLE BUTTONS *****************************************

  const handleButtonClick = (button: string) => {
      setClickedButton(button);
  };
  const [screenContent, setScreenContent] = useState<JSX.Element | null>(null);

  const handleButton1Click = () => {
    handleButtonClick("B1");
  };

  const handleButton2Click = () => {
    handleButtonClick("B2");
  };

  const handleButton3Click = () => {
    handleButtonClick("B3");
  };

  const handleButton4Click = () => {
    handleButtonClick("B4");
  };

  const handleButton5Click = () => {
    handleButtonClick("B5");
  };

  const handleButton6Click = () => {
    handleButtonClick("B6");
  };

  const handleButton7Click = () => {
    handleButtonClick("B7");
  };

  const changeActiveButtons = (newArray:string[]) => {
    // Update the state in the parent component with the new array
    setActiveButtons(newArray);
  };

  const handleAlertMessage = (flag:boolean, message="") => {
    // Update the state in the parent component with the new Array
    setShowAstuce(flag);
    setAlertMessage(message);
  };

  useEffect(() => {
    switch (selectedScenario) {
      case Scenarios.ScenarioEasyStart:
        setScreenContent(<ScenarioEasyStart 
          setSelectedScenario={setSelectedScenario}
          clickedButton={clickedButton} 
          changeActiveButtons={changeActiveButtons} 
          handleAlertMessage={handleAlertMessage} 
          />);
        break;
      case Scenarios.ScenarioTestCombustion:
        setScreenContent(<ScenarioTestCombustion 
          clickedButton={clickedButton}
          setSelectedScenario={setSelectedScenario}
          changeActiveButtons={changeActiveButtons} 
          handleAlertMessage={handleAlertMessage}
          seed={seed} 
          />);
        break;
      case Scenarios.ScenarioMenu:
        setScreenContent(<ScenarioMenu 
          clickedButton={clickedButton} 
          changeActiveButtons={changeActiveButtons} 
          handleAlertMessage={handleAlertMessage} 
          setSelectedScenario={setSelectedScenario}
          setSeed={setSeed}
          />);
        break;
      // Add cases for other scenarios
      default:
        setScreenContent(null);
        break;
    }
    return()=> setClickedButton(null);
  }, [selectedScenario, clickedButton]);


// ************************** Handle Animation *****************************************
  useEffect(() => {
    const timer = setTimeout(() =>{
      setShowAnimation(false);
    },3000);
    return () => clearTimeout(timer);
  }, []);


  const [invertColor, setInvertColor] = useState(false);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    if (showAnimation) {
      let counter = 0;
      interval = setInterval(() => {
        setInvertColor((prevValue) => !prevValue);
        counter++;

        if (counter >= 2) {
          clearInterval(interval);
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [showAnimation]);

  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  // ************************** RETURNED COMPONENT *****************************************

  return (
    <>
      <div  className="relative ">
        
        {/* Animation  */}
        <div className={`${styles['image-boitier']} z-10 ${showAnimation ? '' :styles['hidden'] }  `}>
        <img
          src="images/IHM.svg"
          alt="Cover Image"
          onLoad={handleImageLoad}
        />
        <div className={`absolute inset-0 max-w-400 remote-control ${imageLoaded ? "opacity-100" : "opacity-0"}`}>
            <div className="grid grid-cols-5 gap-4">
              <div className="col-start-2 col-span-3 h-44 bg-black ">
              </div>
            </div>
        </div>
        <button className={` ${invertColor ? styles['button-on-off-clicked'] :styles['button-on-off'] } ${imageLoaded ? "opacity-100" : "opacity-0"} relative top-[-95px]  `}>
          <img className="w-20 inline" src= {` ${invertColor ? "images/on-off-clicked.svg" : "images/on-off.svg"} `}  alt="" />
        </button>
        </div>
        {/* First Screen */}
        <img src="images/IHM-cropped.svg" alt="Cover Image" className={`${styles['image-boitier'] }`} />
        <div className= {`${showAnimation ? styles['remote-control-hidden'] :'' } absolute inset-0 max-w-400 remote-control `}>
          <div className="grid grid-cols-5 gap-4">

            <button onClick={handleButton1Click} disabled={!activeButtons.includes('B1')}  className={`${styles['rounded-button'] }`}>
              <img
                  className='w-5 inline'
                  src={`images/chauffage-${activeButtons.includes('B1') ? 'active' : 'inactive'}.svg`}
                  alt=''
                />
            </button>

            {/* Screen Content */}
            <div className="col-span-3 row-span-2  bg-black ">{screenContent}</div>

            <button onClick={handleButton2Click} disabled={!activeButtons.includes('B2')}  className={` ${styles['rounded-button'] }`}>
              <img
                className='w-5 inline'
                src={`images/up-${activeButtons.includes('B2') ? 'active' : 'inactive'}.svg`}
                alt=''
              />            
            </button>

            <button onClick={handleButton3Click} disabled={!activeButtons.includes('B3')}  className={`${styles['rounded-button'] } `}>
              <img
                className='w-5 inline'
                src={`images/robinet-${activeButtons.includes('B3') ? 'active' : 'inactive'}.svg`}
                alt=''
              />            
            </button>
            
            <button onClick={handleButton4Click} disabled={!activeButtons.includes('B4')}  className={`${styles['rounded-button'] }`}>
            <img
                className='w-5 inline'
                src={`images/down-${activeButtons.includes('B4') ? 'active' : 'inactive'}.svg`}
                alt=''
              />
            </button>
          </div>

          <div className="items-center mt-6">
            <button onClick={handleButton5Click} disabled={!activeButtons.includes('B5')}  className={` mx-8 ${styles['rounded-button'] }`}>
              <img
                className='w-5 inline'
                src={`images/return-${activeButtons.includes('B5') ? 'active' : 'inactive'}.svg`}
                alt=''
              />
            </button>
            <button onClick={handleButton6Click} disabled={!activeButtons.includes('B6')}  className={` mx-8 ${styles['rounded-button'] }`}>
              <img
                className='w-5 inline'
                src={`images/ok-${activeButtons.includes('B6') ? 'active' : 'inactive'}.svg`}
                alt=''
              />              
            </button>
            <button onClick={handleButton7Click} disabled={!activeButtons.includes('B7')} className={` mx-8 mt-3 ${styles['rounded-button']} `}>
              <img
                className='w-5 inline'
                src={`images/menu-${activeButtons.includes('B7') ? 'active' : 'inactive'}.svg`}
                alt=''
              />             
            </button>
          </div>

          <div className= {` mt-24 ${showAstuce ? '' : 'hidden'}`} >
            
            {showAlert && <Alert message={alertMessage} onClose={handleCloseAlert} />
            }
            {!showAlert && 
            <div>
              <AiOutlineInfoCircle className="text-orange-600 inline text-3xl" onClick={handleAlertClick} />
            </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default RemoteControlNaema;
