import React from 'react';
import { LuChevronsUpDown } from 'react-icons/lu';

type DateSelectionProps = {
  isActive: boolean;
  label: string;
  selection: string;
  customClass: string;
  customWidth: string;
};

const DateSelection: React.FC<DateSelectionProps> = ({ 
    isActive, 
    label, 
    selection, 
    customClass,
    customWidth,}) => (
  <div className={`${isActive ? 'bg-[#c3cbd9] text-black' : 'bg-[#686C73] text-[#C3CBD9]'} ${customClass} text-[11px] inline py-2`}>
    <div className={` inline-block font-exo2 ${customWidth}`}>
      {isActive && <LuChevronsUpDown className='inline text-base' />}
      {selection}
    </div>
  </div>
);

export default DateSelection;
