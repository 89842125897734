import { FaFlag } from "react-icons/fa";
import InstallationModal from "./InstallationModal";


const MenuPramListPAC: React.FC<{menuElement:string,selectedButton?:string,showModal?:boolean}> = ({menuElement,selectedButton,showModal  })=>{
    return (
        <>
        <div className={` ${showModal === true ? "absolute" : "hidden"}`}>
          <div>
          <InstallationModal selectedButton = {selectedButton ??''} />
          </div>
        </div>
        <div className=" mx-1 mb-2">

            <div className={`${(menuElement === 'LANG' ) ? ' bg-[#C3CBD9]' : 'bg-[#45474D]'} flex justify-between items-center mt-1 `}>   
                <div className={`${(menuElement === 'LANG' ) ? ' text-[#222526]' : 'text-[#C3CBD9]'} text-[14px] px-3 py-2 w-full flex justify-center text-center `}>
                    <span className="flex-grow">Langue</span>
                    <FaFlag className=" text-md mt-1" />
                </div>
            </div>

            <div className={`${(menuElement === 'DH' ) ? ' bg-[#C3CBD9]' : 'bg-[#45474D]'} flex justify-between items-center mt-1 `}>   
                <div className={`${(menuElement === 'DH' ) ? ' text-[#222526]' : 'text-[#C3CBD9]'} text-[14px] px-3 py-2 w-full text-center `}>
                Date / Heure
                </div>
            </div>

            <div className={`${(menuElement === 'NDC' ) ? ' bg-[#C3CBD9]' : 'bg-[#45474D]'} flex justify-between items-center mt-1 `}>   
                <div className={`${(menuElement === 'NDC' ) ? ' text-[#222526]' : 'text-[#C3CBD9]'} text-[14px] px-3 py-2 w-full text-center `}>
                Noms des Circuits
                </div>
            </div>
            
            <div className={`${(menuElement === 'WIFI' ) ? ' bg-[#C3CBD9]' : 'bg-[#45474D]'} flex justify-between items-center mt-1 `}>   
                <div className={`${(menuElement === 'WIFI' ) ? ' text-[#222526]' : 'text-[#C3CBD9]'} text-[14px] px-3 py-2 w-full text-center `}>
                Wi-Fi
                </div>
            </div>
            
            <div className={`${(menuElement === 'ENT' ) ? ' bg-[#C3CBD9]' : 'bg-[#45474D]'} flex justify-between items-center mt-1 `}>   
                <div className={`${(menuElement === 'ENT' ) ? ' text-[#222526]' : 'text-[#C3CBD9]'} text-[14px] px-3 py-2 w-full text-center `}>
                Entretien
                </div>
            </div>

            <div className={`${(menuElement === 'NOT' ) ? ' bg-[#C3CBD9]' : 'bg-[#45474D]'} flex justify-between items-center mt-1 `}>   
                <div className={`${(menuElement === 'NOT' ) ? ' text-[#222526]' : 'text-[#C3CBD9]'} text-[14px] px-3 py-2 w-full text-center `}>
                Notice
                </div>
            </div>
            <div className={`${(menuElement === 'VL' ) ? ' bg-[#C3CBD9]' : 'bg-[#45474D]'} flex justify-between items-center mt-1 `}>   
                <div className={`${(menuElement === 'VL' ) ? ' text-[#222526]' : 'text-[#C3CBD9]'} text-[14px] px-3 py-2 w-full text-center `}>
                Version Logiciel
                </div>
            </div>
            <div className={`${(menuElement === 'MI' ) ? ' bg-[#C3CBD9]' : 'bg-[#45474D]'} flex justify-between items-center mt-1 `}>   
            <div className={`${(menuElement === 'MI' ) ? ' text-[#222526]' : 'text-[#C3CBD9]'} text-[14px] px-3 py-2 flex-grow flex justify-center text-center`}>
                
                <span className="flex-grow">Menu Installateur</span>
            
                <img className='w-4' src={`images/Installateur-${(menuElement ==='MI')? 'inactive':'active'}.svg`} alt='' /> 
            
            </div>

            </div>
        </div>
        </>
    )
}

export default MenuPramListPAC;