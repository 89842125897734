import React from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { IoIosClose } from 'react-icons/io';

interface AlertProps {
  message: string;
  onClose: () => void;
}

const AlertPAC: React.FC<AlertProps> = ({ message, onClose }) => {
  return (
    <div className="bg-white border-2 rounded-md border-[#ED725C] w-[208px] text-xs px-2 mx-2">
      <div className="flex justify-between my-1">
        <div>
          <div className="flex items-center mr-4">
              <span>
                <AiOutlineInfoCircle className="text-[#ED725C] inline text-3xl" />
              </span>
            <span className="ml-2 text-lg text-[#ED725C] font-bold">
              Astuce
            </span>
          </div>
          <div className='ml-4 my-3 text-left text-[#4D4D4D]' style={{ whiteSpace: 'pre-wrap' }}>{message}</div>
        </div>
        <button className='h-[3em]' onClick={onClose}>
            <IoIosClose className="text-2xl  text-[#969696]"  />
        </button>
      </div>
    </div>
  );
};

export default AlertPAC;
