import { useEffect, useRef, useState } from 'react';
import Wheel from './wheel'; // Assuming Wheel class is in a separate file
import styles from '../../../styles/RemoteControlPAC.module.css';

interface wheelComponentProps {
  up: VoidFunction,
  down: VoidFunction,
  ok: VoidFunction,
  activeButtons: string[],
  wheelShadowColor: string,
  setTouch: any;
}

const WheelComponent = (props:wheelComponentProps) => {
    const { up, down, ok, activeButtons, wheelShadowColor, setTouch } = props; 
    const wheelRef = useRef<Wheel | null>(null);

    const [isWheelClicked, setIsWheelClicked] = useState(true);
    useEffect(() => {
      // Initialize Wheel instance if it doesn't exist
      if (!wheelRef.current) {
        wheelRef.current = new Wheel(activeButtons);
        wheelRef.current.handleDown = down;
        wheelRef.current.handleUp = up;
      }
      return () => {
        // Cleanup logic here if necessary
      };
    }, []); 
  
    // update the Wheel instance's active buttons whenever they change
    useEffect(() => {
      if (wheelRef.current) {
        wheelRef.current.setActiveButtons(activeButtons);
        wheelRef.current.initializePositions();
      }
    }, [activeButtons]); 
  
    const handleOkClick = () => {
      if (activeButtons.includes('OK')) {
        ok();
      }
    };

    const handleDocumentClick = (event: MouseEvent) => {
      const wheelContainer = document.getElementById('wheel');
      const Page = document.getElementById("root")
      const menuButton = document.getElementById("menu-button")
      const returnButton = document.getElementById("return-button")
      const astuceButton = document.getElementById("astuce-button")
      if (wheelContainer && !wheelContainer.contains(event.target as Node) && Page?.contains(event.target as Node) && !menuButton?.contains(event.target as Node) && !returnButton?.contains(event.target as Node) && !astuceButton?.contains(event.target as Node)) {
        setIsWheelClicked(false); // Set variable to false when clicked outside of the wheel
      }
    };

    useEffect(() => {
      document.addEventListener('click', handleDocumentClick);
      return () => {
        document.removeEventListener('click', handleDocumentClick);
      };
    }, []);

    useEffect(() => {
      if(isWheelClicked){
        setTouch("none")
      } else {
        setTouch("")
      }
    }, [isWheelClicked]);

    const handleWheelClick = () => {
      setIsWheelClicked(true); // Set variable to true when wheel is clicked
    };

    return (
    <div className={`${styles['nested-ellipses-container']}`}  style={{boxShadow: `0 0 12px 5px ${wheelShadowColor}`}}
      id="wheel" onClick={handleWheelClick}>
      <div className={`${styles['outer-ellipse'] }`}></div>
      <div className={`${styles['middle-ellipse'] }`}></div>
      <div className={`${styles['inner-ellipse'] }`} onClick={handleOkClick}></div>
    </div>
    );
};

export default WheelComponent;



