import { FiCheckCircle } from 'react-icons/fi';

const InstallationModal= ({ selectedButton }: { selectedButton: string }) => {

    return (
      <div className=" ">
          <div className="bg-[#3a3c40] h-[16.5rem] w-[12.7rem]  m-2 ">
              <div className="text-[10px] p-2 text-[#fff]">
                  <div className=" ">Menu Installateur</div>
                  <div className="flex justify-center items-center rounded-full">
                  <img
                        className='w-16 inline'
                        src={`images/Installateur-active.svg`}
                        alt=''
                    /> 
                  </div>
                  <div className=" ">Attention! 
                  <br/>Réservé aux personnes
                  <br/>qualifiées !
                  <br/>(XXX jours
                  <br />depuis dernier accès)</div>
                  
                  <div className="border-2  border-[#e2ebff]">
                    <div className={`${selectedButton ==='quit'? ' bg-[#e2ebff]  text-[#3a3c40]':' bg-[#3a3c40] text-[#e2ebff]'}   text-[11px] m-1 `}>
                        <div className=' inline-block w-30 py-1'>Quitter</div>
                    </div>
                  </div>
                  <div className="border-2  border-[#e2ebff] mt-2">
                    <div className={`${selectedButton ==='access'? ' bg-[#e2ebff]  text-[#3a3c40]':' bg-[#3a3c40] text-[#e2ebff]'} text-[11px] m-1 `}>
                        <div className=' inline-block w-30 py-1'>Accéder</div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
    );
  };
  
  export default InstallationModal;
  