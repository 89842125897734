import { AiOutlineCheck } from "react-icons/ai"
import { BsExclamationTriangleFill } from "react-icons/bs";
import WarningAirPurgeMessage from "./WarningAirPurgeMessage";
import ValidationScreenPAC from "../../ScenarioEasyStartPAC/components/ValidationScreenPAC";
import { useEffect, useState } from "react";
import { WarningAirPurgeMode } from "../../../../../../shared/types";
import FunctioningPopUp from "./FunctioningPopUp";
import PressurePopUp from "./PressurePopUp";
import WaterPopUp from "./WaterPopUp";
import TemperaturePopUp from "./TemperaturePopUp";


type WarningAirPurgeProps = {
    mode: string;
    showMessage?:boolean

};

const AirPurgeReport: React.FC<WarningAirPurgeProps> = (({ mode,showMessage }) => {


    return (
        <>
            <div className="flex justify-center">
                <div className={(mode === "FUNCTION"&& showMessage )? "absolute" : "hidden"}>
                    <FunctioningPopUp />
                </div>
                <div className={(mode === "PRESSURE"&& showMessage )? "absolute" : "hidden"}>
                    <PressurePopUp />
                </div>
                <div className={(mode === "WATER"&& showMessage )? "absolute" : "hidden"}>
                    <WaterPopUp />
                </div>
                <div className={(mode === "TEMPERATURE_RDC"&& showMessage )? "absolute" : "hidden"}>
                    <TemperaturePopUp index={1} title={"Rez de chaussée"} />
                </div>
                <div className={(mode === "TEMPERATURE_ETAGE"&& showMessage )? "absolute" : "hidden"}>
                    <TemperaturePopUp index={2} title={"Etage"} />
                </div>
            </div>
            <div className="h-full p-2 ">
                <div className=" h-1/5 flex flex-row  text-white ">
                    <div  className={`${mode === "FUNCTION" ? "bg-[#5a5e66]" : "bg-[#16181a]"}  w-1/2  flex flex-row items-center mr-1 `}>
                        <div className="  text-xs font-extralight p-2">
                            <AiOutlineCheck className="inline rounded  bg-[#63AE65] p-1 w-8 h-8 mr-4" />
                        </div>
                        <div>
                            <div className="basis-1/2">
                                <span>OK</span>
                            </div>
                        </div>
                        
                    </div>
                    <div  className={`${mode === "PRESSURE" ? "bg-[#5a5e66]" : "bg-[#16181a]"}  w-1/2  text-xs font-extralight p-2 flex flex-row  items-center`}>
                            <div className="basis-1/2">
                                <img
                                className=' w-7 inline mr-2'
                                src="images/pressure.svg"
                                alt=''
                                /> 
                            </div>
                            <div className="basis-1/2">
                                <span className="text-xl">1.5</span>  <br /> bar
                            </div>
                    </div>
                </div>
                <div className={`${mode === "WATER" ? "bg-[#5a5e66]" : "bg-[#16181a]"} h-2/5  flex justify-center items-center relative mt-1`}>
                    <img className='absolute left-2 top-2 w-2' src={`images/robinet-active.svg`} alt='' />
                    <img className='w-16' src={`images/Goutte-red.svg`} alt='' />
                </div>
                <div className=" h-2/5 flex flex-row  text-white mt-1">
                    
                    <div className={`${mode === "TEMPERATURE_RDC" ? "bg-[#5a5e66]" : "bg-[#16181a]"}  w-1/2  flex flex-row items-center justify-center mr-1 relative`}>
                        <div className='absolute left-2 top-2 tet-xs text-white' >1</div>
                        <img className=' w-10' src={`images/Cercle-temperature-43.svg`} alt='' /> 
                    </div>
                    <div className={`${mode === "TEMPERATURE_ETAGE" ? "bg-[#5a5e66]" : "bg-[#16181a]"}  w-1/2  flex flex-row items-center justify-center relative`}>
                        <div className='absolute left-2 top-2 tet-xs text-white' >2</div>
                        <img className=' w-10' src={`images/Cercle-temperature-45.svg`} alt='' /> 
                    </div>
                </div>
                
            </div>
        </>

    )

})

export default AirPurgeReport;
