import './App.css'
import React, { useEffect, useState } from 'react';
import AppLayout from './components/AppLayout';
import ImageBox from './components/ImageBox';
import RemoteControlNaema from './components/RemoteControls/RemoteControlNaema/RemoteControlNaema';
import { Scenarios,ScenariosInfo } from './shared/types';
import styles from './styles/App.module.css';
import { BiChevronLeft } from 'react-icons/bi';
import RemoteControlPAC from './components/RemoteControls/RemoteControlPAC/RemoteControlPAC';



function App() {
  const [selectedScenario, setSelectedScenario] = useState<Scenarios | null>(null);

  const handleBoxClick = (boxId: Scenarios | null) => {
    setSelectedScenario(boxId);
  };
  useEffect(() => {
    console.log('[APP] setSelectedScenario changed',selectedScenario);
  },[selectedScenario]);

  const [showSplashScreen,setshowSplashScreen] = useState(true);
  useEffect(()=>{
    const timer = setTimeout(()=>{
      setshowSplashScreen(false);
    },2000);
    return() =>clearTimeout(timer);
  },[]);
  return (
    <>

    {/* splash screen */}
    <div className={`${styles['splash-screen']} bg-black ${showSplashScreen ? '' : styles['hidden']}`}>
      <img src="images/splash-screen.png" alt="" className='h-screen w-screen object-cover' />
      <div className="flex items-center justify-center h-screen w-screen absolute top-0 left-0">
        <div className="bg-white p-4">
        <img
            src="images/atlantic-france-logo-vector.svg"
            alt="Atlantic logo"
            className="mx-auto my-4 w-28"
          />
        </div>
      </div>
    </div>

    
    <AppLayout>
      {!selectedScenario && (
        // ******************************* Accueil list ************************************
        <>
          <p className=" my-12 font-semibold text-3xl text-[#ed725c]">Sélection du type d’appareil</p>
          <div className="">
            <ImageBox imageSrc="images/Interface-boitier-Chaudiere.svg"  name="Chaudières" type="Naema 3 / Effinox 2" setSelectedScenario={handleBoxClick}  easyStartId={Scenarios.ScenarioEasyStart} menuId={Scenarios.ScenarioMenu}  />
            <ImageBox imageSrc="images/Interface-boitier-PAC.svg"  name="Pompes à chaleur" type="Alfea Excellia S / Alfea M / Hybea" setSelectedScenario={handleBoxClick}  easyStartId={Scenarios.ScenarioEasyStartPAC} menuId={Scenarios.ScenarioMenuPAC}   />
          </div>
        </>
        // ******************************* End Accueil list ************************************
      )}
      {selectedScenario && (
        <div className="bg-orange-50 pt-10 h-[900px]">
          <div className="box-info  justify-center items-center mx-auto">
            <div className="grid grid-cols-4 mb-6 mx-0">
              <p className=" text-[#ED725C]  text-left mt-4 text-sm cursor-pointer" onClick={() => handleBoxClick(null)}>
                <BiChevronLeft className=" inline text-2xl " />Retour
              </p>
              <div className=' col-span-2'>
                <h2 className=" text-[#ED725C] font-bold">{selectedScenario && ScenariosInfo[selectedScenario]?.type}</h2>
                <p className=" text-xs text-center text-[#4D4D4D]">Interface de régulation</p>
              </div>
            </div>
            <div>
              {(selectedScenario === Scenarios.ScenarioEasyStart || selectedScenario === Scenarios.ScenarioMenu || selectedScenario === Scenarios.ScenarioTestCombustion) &&
                  <RemoteControlNaema selectedScenario={selectedScenario} setSelectedScenario={setSelectedScenario} />}
              
              {(selectedScenario === Scenarios.ScenarioEasyStartPAC || selectedScenario === Scenarios.ScenarioMenuPAC || selectedScenario === Scenarios.ScenarioAccueilPAC )&&
                  <RemoteControlPAC selectedScenario={selectedScenario} setSelectedScenario={setSelectedScenario} />}
            </div>
          </div>
        </div>
      )}
    </AppLayout>
    </>
  );
}

export default App;
