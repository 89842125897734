import { IoIosSettings } from "react-icons/io";


const MenuListPAC: React.FC<{menuElement:string}> = ({menuElement})=>{
    return (
        <>
        <div className=" mx-1 mb-2">

            <div className={`${(menuElement === 'SA' ) ? ' bg-[#C3CBD9]' : 'bg-[#45474D]'} flex justify-between items-center mt-1 `}>   
                <div className={`${(menuElement === 'SA' ) ? ' text-[#222526]' : 'text-[#C3CBD9]'} text-[14px] px-3 py-2 w-full text-center `}>
                Services Actifs
                </div>
            </div>

            <div className={`${(menuElement === 'ECS' ) ? ' bg-[#C3CBD9]' : 'bg-[#45474D]'} flex justify-between items-center mt-1 `}>   
                <div className={`${(menuElement === 'ECS' ) ? ' text-[#222526]' : 'text-[#C3CBD9]'} text-[14px] px-3 py-2 w-full text-center `}>
                Eau Chaude Sanitaire
                </div>
            </div>

            <div className={`${(menuElement === 'AC' ) ? ' bg-[#C3CBD9]' : 'bg-[#45474D]'} flex justify-between items-center mt-1 `}>   
                <div className={`${(menuElement === 'AC' ) ? ' text-[#222526]' : 'text-[#C3CBD9]'} text-[14px] px-3 py-2 w-full text-center `}>
                Abaissement Chauffage
                </div>
            </div>
            
            <div className={`${(menuElement === 'ABS' ) ? ' bg-[#C3CBD9]' : 'bg-[#45474D]'} flex justify-between items-center mt-1 `}>   
                <div className={`${(menuElement === 'ABS' ) ? ' text-[#222526]' : 'text-[#C3CBD9]'} text-[14px] px-3 py-2 w-full text-center `}>
                Absence
                </div>
            </div>
            
            <div className={`${(menuElement === 'SDE' ) ? ' bg-[#C3CBD9]' : 'bg-[#45474D]'} flex justify-between items-center mt-1 `}>   
                <div className={`${(menuElement === 'SDE' ) ? ' text-[#222526]' : 'text-[#C3CBD9]'} text-[14px] px-3 py-2 w-full text-center `}>
                Suivi des Energies
                </div>
            </div>

            <div className={`${(menuElement === 'PAR' ) ? ' bg-[#C3CBD9]' : 'bg-[#45474D]'} flex justify-between items-center mt-1 `}>   
                <div className={`${(menuElement === 'PAR' ) ? ' text-[#222526]' : 'text-[#C3CBD9]'} text-[14px] px-3 py-2 w-full flex justify-center text-center `}>
                <span className="flex-grow">Paramètres</span>
                <IoIosSettings className="text-xl" />
                </div>
            </div>

        </div>
        </>
    )
}

export default MenuListPAC;