import React from 'react';
import TimeElement from './TimeElement';
import EasyStartPACFinished from './EasyStartPACFinished';
import ValidationScreenPAC from './ValidationScreenPAC';

type InstallationSelectionProps = {
  label: string;
  UniteExtSelection: string;
  instalationfinished: boolean;
  easyStartFinishedButton: string;
  validationScreen?: boolean;

};

const InstallationSelectionPAC: React.FC<InstallationSelectionProps> = ({
  label,
  UniteExtSelection,
  instalationfinished,
  easyStartFinishedButton,
  validationScreen
}) => (
  <>
    <div className={` ${validationScreen === true ? "absolute" : "hidden"}`}>
          <div>
          <ValidationScreenPAC />
          </div>
    </div>
    <div className={` ${instalationfinished === true ? "absolute" : "hidden"}`}>
          <div>
          <EasyStartPACFinished easyStartFinishedButton = {easyStartFinishedButton} />
          </div>
    </div>
    <div>
     <div className={`${(label === 'UniteExt' ) ? ' bg-[#5a5e66]' : 'bg-[#17181a]'}  mx-1 mb-1 pl-2`}>
      <p className='text-left text-[10px] text-[#C3CBD9] font-lato'>Modèle Unité Extérieure</p>
      <div className='flex items-center justify-end'>        
        <TimeElement isActive={label === 'UniteExt'} selection={UniteExtSelection} customClass='' customWidth='' />
      </div>
    </div>

    <div className={`${(label === 'EauChaudeExterne' ) ? ' bg-[#5a5e66]' : 'bg-[#17181a]'}  mx-1 mb-1 pl-2`}>
      <p className='text-left text-[10px] text-[#C3CBD9] font-lato'>Option Eau Chaude Externe</p>
      <div className='flex items-center justify-end'>        
        <TimeElement isActive={label === 'EauChaudeExterne'} selection={'Non'} customClass='' customWidth='' />
      </div>
    </div>

    <div className={`${(label === 'AppointChauffage' ) ? ' bg-[#5a5e66]' : 'bg-[#17181a]'}  mx-1 mb-1 pl-2`}>
      <p className='text-left text-[10px] text-[#C3CBD9] font-lato'>Appoint Chauffage</p>
      <div className='flex items-center justify-end'>        
        <TimeElement isActive={label === 'AppointChauffage'} selection={'Aucun'} customClass='' customWidth='' />
      </div>
    </div>

    <div className={`${(label === 'NombreCircuits' ) ? ' bg-[#5a5e66]' : 'bg-[#17181a]'}  mx-1 mb-1 pl-2`}>
      <p className='text-left text-[10px] text-[#C3CBD9] font-lato'>Nombre Circuits Chauffage</p>
      <div className='flex items-center justify-end'>        
        <TimeElement isActive={label === 'NombreCircuits'} selection={'2'} customClass='' customWidth='' />
      </div>
    </div>

    <div className={`${(label === 'Circuit1Nom' ) ? ' bg-[#5a5e66]' : 'bg-[#17181a]'}  mx-1 mb-1 pl-2`}>
      <p className='text-left text-[10px] text-[#C3CBD9] font-lato'>Circuit 1: Nom</p>
      <div className='flex items-center justify-end'>        
        <TimeElement isActive={label === 'Circuit1Nom'} selection={'RDC'} customClass='' customWidth='' />
      </div>
    </div>

    <div className={`${(label === 'Circuit1Type' ) ? ' bg-[#5a5e66]' : 'bg-[#17181a]'}  mx-1 mb-1 pl-2`}>
      <p className='text-left text-[10px] text-[#C3CBD9] font-lato'>Circuit 1 : Type d'émetteur</p>
      <div className='flex items-center justify-end'>        
        <TimeElement isActive={label === 'Circuit1Type'} selection={'Plancher'} customClass='' customWidth='' />
      </div>
    </div>


    <div className={`${(label === 'Circuit1Confort' ) ? ' bg-[#5a5e66]' : 'bg-[#17181a]'}  mx-1 mb-1 pl-2`}>
      <p className='text-left text-[10px] text-[#C3CBD9] font-lato'>Circuit 1: Confort servi</p>
      <div className='flex items-center justify-end'>        
        <TimeElement isActive={label === 'Circuit1Confort'} selection={'Chauffage et Froid'} customClass='' customWidth='' />
      </div>
    </div>


    <div className={`${(label === 'Circuit2Nom' ) ? ' bg-[#5a5e66]' : 'bg-[#17181a]'}  mx-1 mb-1 pl-2`}>
      <p className='text-left text-[10px] text-[#C3CBD9] font-lato'>Circuit 2: Nom</p>
      <div className='flex items-center justify-end'>        
        <TimeElement isActive={label === 'Circuit2Nom'} selection={'Etage'} customClass='' customWidth='' />
      </div>
    </div>


    <div className={`${(label === 'Circuit2Type' ) ? ' bg-[#5a5e66]' : 'bg-[#17181a]'}  mx-1 mb-1 pl-2`}>
      <p className='text-left text-[10px] text-[#C3CBD9] font-lato'>Circuit 2: Type d'émetteur</p>
      <div className='flex items-center justify-end'>        
        <TimeElement isActive={label === 'Circuit2Type'} selection={'Radiateurs'} customClass='' customWidth='' />
      </div>
    </div>


    <div className={`${(label === 'Circuit2Confort' ) ? ' bg-[#5a5e66]' : 'bg-[#17181a]'}  mx-1 mb-1 pl-2`}>
      <p className='text-left text-[10px] text-[#C3CBD9] font-lato'>Circuit 2: Confort servi</p>
      <div className='flex items-center justify-end'>        
        <TimeElement isActive={label === 'Circuit2Confort'} selection={'Chauffage seul'} customClass='' customWidth='' />
      </div>
    </div>


    

  </div>
  </>

);

export default InstallationSelectionPAC;
