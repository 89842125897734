import ValidationScreen from "../../../../../../shared/components/ValidationScreen";

type ReportEasyStartProps = {
    currentStep: number;
    kityesNoOption: string;
    transmitterTypeSelection: string;
    pressureThresholdSelection: number;
}

// send the necessary info and the current step
const ReportEasyStart: React.FC<ReportEasyStartProps> =({ 
    currentStep,
    kityesNoOption,
    transmitterTypeSelection,
    pressureThresholdSelection 
}) =>(
    <>
         <div className={` ${currentStep === 13 ? "absolute" : "hidden"}`}>
            <div>
            <ValidationScreen />
            </div>
        </div>
        <div className=" mx-1 mt-9">
            <div className="flex justify-between items-center  mt-1 bg-[#45474D]">
            <div className="text-[10px] px-3 py-2 w-1/2 text-left text-[#C3CBD9] "> Kit 2 circuits</div>
            <div className="text-[10px] px-3 py-2 w-1/2 text-right text-[#A0A7B3] ">{kityesNoOption} </div>
            </div>

            <div className="flex justify-between items-center  mt-1 bg-[#45474D]">
            <div className="text-[10px] px-3 py-2 w-1/2 text-left text-[#C3CBD9] ">Circuit 1</div>
            <div className="text-[10px] px-3 py-2 w-1/2 text-right text-[#A0A7B3] ">{transmitterTypeSelection}</div>
            </div>

            <div className="flex justify-between items-center  mt-1 bg-[#45474D]">
            <div className="text-[10px] px-3 py-2 w-1/2 text-left text-[#C3CBD9]">Pression cible</div>
            <div className="text-[10px] px-3 py-2 w-1/2 text-right text-[#A0A7B3]">{pressureThresholdSelection} bar </div>
            </div>

            {currentStep === 12 ? (
            <div className='bg-[#C3CBD9] text-[#222526] text-[11px] inline py-2 '>
                <div className=' inline-block w-32 my-6'>Terminer</div>
            </div>
            
            ) : (

            <div className='bg-[#45474D] text-[#C3CBD9] text-[11px] inline py-2 border-[#C3CBD9] border-solid border-2'>
                <div className=' inline-block w-32 my-6'>Terminer</div>
            </div>
            )}
        </div>
    </>
    );
  
export default ReportEasyStart;