import React from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { IoIosClose } from 'react-icons/io';

interface AlertProps {
  message: string;
  onClose: () => void;
}

const Alert: React.FC<AlertProps> = ({ message, onClose }) => {
  return (
    <div className="bg-white border-2 rounded-md border-[#ED725C]  text-xs px-2  mb-4">
      <div className="flex justify-between items-center">
        <div className="flex items-center my-4 mr-4">
            <span>

            <AiOutlineInfoCircle className="text-[#ED725C]  inline text-3xl" />
            </span>
          
          <span className="ml-2 text-lg text-[#ED725C] font-bold">
             Astuce
          </span>
          <span className='ml-4 text-left text-[#4D4D4D]'>{message}</span>
        </div>
        <button className='mb-8' onClick={onClose}>
            <IoIosClose className="text-2xl  text-[#969696]"  />
        </button>
        
      </div>
    </div>
  );
};

export default Alert;
