import React, { useState, useEffect } from "react";

const CompleteInstalation: React.FC<{ selectedPressure: number, finished: boolean }> = ({ selectedPressure, finished }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (finished) {
      setProgress(100);
    }else{
      const duration = 3000; // 3 seconds in milliseconds
      const steps = 100;
      const interval = duration / steps;
  
      const updateProgress = () => {
        setProgress((prevProgress) => {
          const newProgress = prevProgress + 1;
          return newProgress <= 100 ? newProgress : 100;
        });
      };
  
      const timer = setInterval(updateProgress, interval);
  
      return () => {
        clearInterval(timer);
      };
    }
  }, []);

  return (
    <div className=" ">
      <div className="bg-[#222526] h-32  m-2 flex 
      ">
        
        <div className="text-[11px] p-2 text-[#C3CBD9]">
        <div className="w-[13rem] m-3 bg-[#686C73] border-2 border-[#C3CBD9] h-4">
            <div
            className=" border-r-2 border-r-[#C3CBD9] h-3" 
            style={{ width: `${progress}%` }}
            />
        </div >
          <div>{selectedPressure}bar</div>
          <div>Remplir l’installation</div>
          { finished ==true && (
          <div className='bg-[#C3CBD9] text-[#222526] text-[11px] inline py-2 '>
              <div className=' inline-block w-32 my-3'>Terminer</div>
          </div>
          )}
        </div>
      </div>
      
    </div>
  );
};

export default CompleteInstalation;
