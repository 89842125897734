import { AiOutlineCheck } from 'react-icons/ai';
const FunctioningPopUp: React.FC = () => {
    return (
      <div  className=" relative z-10 mt-3 ">
      <div className="bg-[#3a3c40] h-[16.5rem] w-[200px] flex justify-center">
        <div className=" mt-4">
            <div> Fonctionnement OK </div> 
            <AiOutlineCheck className=" rounded  bg-[rgb(99,174,101)]  inline-block m-2 text-6xl" />
        </div>
      </div>
      </div>
    );
  };
  
  export default FunctioningPopUp;
  