class Wheel{
	activeButtons: string[];
	lastExecutedAngle: number;
    debounceDelay: number ; 
    lastDebounceTime: number;

    wheelElm: HTMLElement | null;
    isDragging: any;
    currentAngle: number;
    oldAngle: number;
    lastAngles: number[];
    startX: null;
    startY: null;
    positionCallbacks: Function[];
    wheelWidth: any;
    wheelHeight: any;
    wheelX: any;
    wheelY: any;
    isSpinning: any;
    startAngle!: number;
    speed: any;
    direction: any;
	constructor(activeButtons: string[] = []){
		this.lastExecutedAngle = 0;
		this.activeButtons = activeButtons;
		this.wheelElm = document.getElementById('wheel');
		this.wheelElm?.addEventListener('mousedown', e =>{
			this.onGrab(e.clientX, e.clientY);
		});
		window.addEventListener('mousemove', e =>{
			if(e.which == 1)
				this.onMove(e.clientX, e.clientY);
			else if(!this.isDragging)
				this.onRelease()
			
		});
		window.addEventListener('mouseup', this.onRelease.bind(this));

		this.wheelElm!.addEventListener('touchstart', e =>{
			this.onGrab(e.touches[0].clientX, e.touches[0].clientY);
		});
		window.addEventListener('touchmove', e =>{
			this.onMove(e.touches[0].clientX, e.touches[0].clientY);
		});
		window.addEventListener('touchend', this.onRelease.bind(this));

		this.calculatePositions();
		window.addEventListener('resize', this.calculatePositions.bind(this));

		this.currentAngle = 0;
		this.oldAngle = 0;
		this.lastAngles = [0,0,0];
		this.isDragging = false;
		this.startX = null;
		this.startY = null;
		this.positionCallbacks = [];
		this.debounceDelay = 900; // milliseconds
		this.lastDebounceTime = 0;
		this.debounce = this.debounce.bind(this);

	}

	initializePositions() {
        // Re-calculate positions in case the layout has changed
        this.calculatePositions();
    }
	debounce(func: () => void) {
        const currentTime = Date.now();
        if (currentTime - this.lastDebounceTime > this.debounceDelay) {
            func();
            this.lastDebounceTime = currentTime;
        }
    }

    setActiveButtons(activeButtons: string[]) {
        this.activeButtons = activeButtons;
    }

	calculatePositions(){
		this.wheelWidth = this.wheelElm!.getBoundingClientRect()['width'];
		this.wheelHeight = this.wheelElm!.getBoundingClientRect()['height']
		this.wheelX = this.wheelElm!.getBoundingClientRect()['x'] + this.wheelWidth / 2;
		this.wheelY = this.wheelElm!.getBoundingClientRect()['y'] + this.wheelHeight / 2;
	}

	onPositionChange(callback: Function){
		this.positionCallbacks.push(callback);
	}

	onGrab(x: number, y: number){
		if(!this.isSpinning){
			this.isDragging = true;
			this.startAngle = this.calculateAngle(x, y);
		}				
	}


	handleUp() {
		throw new Error("Method not implemented.");
	}
	handleDown() {
		throw new Error("Method not implemented.");
	}

	onMove(x: number, y: number) {
		if (!this.isDragging) return;
		
		this.lastAngles.shift();
		this.lastAngles.push(this.currentAngle);
	
		const deltaAngle = this.calculateAngle(x, y) - this.startAngle;
		this.currentAngle = deltaAngle + this.oldAngle;
		// Calculate the total degrees moved since the last execution
		let degreesMoved = this.currentAngle - this.lastExecutedAngle;
		if (degreesMoved > 0) {
            if (this.activeButtons.includes('DOWN')) {
                this.debounce(this.handleDown);
            }
            this.lastExecutedAngle += degreesMoved; 
        }
		if (degreesMoved < 0) { 
			if (this.activeButtons.includes('UP')) {
                this.debounce(this.handleUp); 
            }
            this.lastExecutedAngle += degreesMoved; 
        }
		this.render(this.currentAngle);
	}
	
	calculateAngle(currentX: number, currentY: number){
		const xLength = currentX - this.wheelX;
		const yLength = currentY - this.wheelY;
		const angle = Math.atan2(xLength, yLength) * (180/Math.PI);
		return 360 - angle;
	}

	onRelease(){
		if(this.isDragging){
			this.isDragging = false;
			this.oldAngle = this.currentAngle;

			const speed = this.lastAngles[0] - this.lastAngles[2];
			this.giveMoment(speed);
		}		
	}

	giveMoment(speed: number){
		const maxSpeed = 3;
		if(speed >= maxSpeed)
			speed = maxSpeed;
		else if(speed <= -maxSpeed)
			speed = -maxSpeed;

		if(speed >= 0.1){
			speed -= 0.1;
			window.requestAnimationFrame(this.giveMoment.bind(this, speed));
			this.isSpinning = true;
		}
		else if(speed <= -0.1){
			speed += 0.1;
			window.requestAnimationFrame(this.giveMoment.bind(this, speed));
			this.isSpinning = true;
		}
		else{
			this.isSpinning = false;
		}

		this.oldAngle -= speed;
		this.render(this.oldAngle);
	}

    getSpeed() {
		return this.speed;
	}

	getdirection() {
		return this.direction;
	}

	render(deg: number){
        const speed = this.lastAngles[0] - this.lastAngles[2];
		this.speed = speed
		this.direction = (speed > 0) ? 0 : 1;
		window?.navigator?.vibrate?.(100);
		this.wheelElm!.style.transform = `rotate(${deg}deg)`;
		for(const callback of this.positionCallbacks){
			callback(deg);
		}
	}
}

export default Wheel;