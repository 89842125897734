import { useEffect, useRef, useState } from "react";
import { Scenarios, ScreenScenarioProps, WarningAirPurgeMode } from "../../../../../shared/types"
import { FaWifi } from "react-icons/fa";
import { CiCloudOn } from "react-icons/ci";
import styles from '../../../../../styles/ScenarioEasyStartPAC.module.css';
import LoadingSpinner from "./components/LoadingSpinner";
import WarningAirPurge from "./components/WarningAirPurge";
import WariningAirPurgeMessage from "./components/WarningAirPurgeMessage";
import AirPurgeReport from "./components/AirPurgeReport";
import { switchToNextElement, switchToPreviousElement } from "../../../../../shared/utils";


type ScrollableContainerRef = React.RefObject<HTMLDivElement>;


const ScenarioAccueilPAC: React.FC<ScreenScenarioProps> = ({ 
    clickedButton,
    seed, 
    changeActiveButtons, 
    handleAlertMessage ,
    handleAlertClick,
    setSelectedScenario,
    setWheelShadowColor,
  })=>{
    

    const WARNING_AIRPURGE_STEP = 1
    const AIRPURGE_REPORT_STEP = 5
    
    const [currentStep, setCurrentStep] = useState(seed!);
    const [scenarioTitle, setScenarioTitle] = useState<JSX.Element>(<>09:24</>);
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(true);
    const [countdownTerminated, setCountdownTerminated] = useState(false);
    const [reportMode, setReportMode] = useState("FUNCTION");
    const [airPurgeActiveButton, setAirPurgeActiveButton] = useState("NOW");
    const reportModes = ['FUNCTION','PRESSURE','WATER','TEMPERATURE_RDC','TEMPERATURE_ETAGE']; 
    const airPurgeActiveButtons = ['NOW','LATER']; 
    const [interrompreCountdown,setInterrompreCountdown]= useState(false)

    const scrollableContainerRef: ScrollableContainerRef = useRef<HTMLDivElement>(null);
      

    const handleCountdownTerminate = () => {
      setCountdownTerminated(true);
  };

  useEffect(() => {
      if (countdownTerminated) {
          setCurrentStep(currentStep => currentStep + 1);
          setCountdownTerminated(false); // Reset for the next use
          handleAlertClick!()
      }
  }, [countdownTerminated]);
  // ***************************** Loading Spinner ***********************
  useEffect(()=>{
    console.log("setShowWelcomeMessage called");
    const timer = setTimeout(()=>{
      setShowLoadingSpinner(false);
    },3000);
    return() =>clearTimeout(timer);
  },[]);


    useEffect(() => {

      switch (currentStep) {
        case WARNING_AIRPURGE_STEP:
          setWheelShadowColor?.("#FCB236")
          changeActiveButtons(['OK']);
          handleAlertMessage(false,"");
          break;
        case WARNING_AIRPURGE_STEP+1:
          changeActiveButtons(['OK', 'RETURN','UP', 'DOWN']);
          handleAlertMessage(false,"");
          break;
        case WARNING_AIRPURGE_STEP+2:
          changeActiveButtons([]);
          handleAlertMessage(false,"");
          break;
        case WARNING_AIRPURGE_STEP+3:
          changeActiveButtons(['OK']);
          handleAlertMessage(true,"Le dégazage dure quelques minutes mais peut être interrompu à tout moment en sélectionnant ⚠️");
          handleAlertClick!()
          break;
        case AIRPURGE_REPORT_STEP:
          setWheelShadowColor?.("white")
          changeActiveButtons(['OK', 'UP', 'DOWN',"MENU"]);
          handleAlertMessage(true,"Le symbole wifi en haut à gauche de l'écran vous permet de visualiser l'état de connexion de votre interface à cozytouch. A paramétrer grâce à l'application de votre client et à accompagner d'un thermostat Navilink pour un accès complet aux fonctionnalités de la connectivité.");
          break;
        case AIRPURGE_REPORT_STEP+1:
          if(reportMode ==="PRESSURE"){
            changeActiveButtons(['RETURN','MENU','OK']);
          }else{
            changeActiveButtons(['RETURN','MENU']);
          }
          handleAlertMessage(true,"Le symbole wifi en haut à gauche de l'écran vous permet de visualiser l'état de connexion de votre interface à cozytouch. A paramétrer grâce à l'application de votre client et à accompagner d'un thermostat Navilink pour un accès complet aux fonctionnalités de la connectivité.");
          break;

        default:
          break;
      }
        }, [currentStep]);
    const renderStep = () => {
        switch (currentStep) {
          case WARNING_AIRPURGE_STEP:
            return <WarningAirPurge mode={WarningAirPurgeMode.None} />;
          case WARNING_AIRPURGE_STEP+1:
            return <WarningAirPurge mode={WarningAirPurgeMode.AirPurgeMessage} activeButton = {airPurgeActiveButton}/>;
          case WARNING_AIRPURGE_STEP+2:
            return <WarningAirPurge mode={WarningAirPurgeMode.ValidationScreen}/>;
          case WARNING_AIRPURGE_STEP+3:
            return <WarningAirPurge mode={WarningAirPurgeMode.Countdown}  onCountdownTerminate={handleCountdownTerminate} interrompre={interrompreCountdown} />;
          case AIRPURGE_REPORT_STEP:
            return <AirPurgeReport mode={reportMode} />;
          case AIRPURGE_REPORT_STEP+1:
            return <AirPurgeReport mode={reportMode} showMessage={true} />;
          default:
            return null;
        }
    }



  const handleButtonClick = (button: string | null) => {
    
    switch (button) {
      case 'OK':
        if (currentStep === WARNING_AIRPURGE_STEP+1 &&airPurgeActiveButton === "LATER" ) {
          setCurrentStep((currentStep) => currentStep - 1);
        }
        else if (currentStep === WARNING_AIRPURGE_STEP+1 ) {
          setCurrentStep((currentStep) => currentStep + 1);
          setTimeout(() => {
            setCurrentStep((currentStep) => currentStep + 1);
          }, 1000); 
        }
        else if (currentStep === WARNING_AIRPURGE_STEP+3 ) {
          setInterrompreCountdown((interrompreCountdown)=>!interrompreCountdown)
          console.log(interrompreCountdown);
        }
        else if (currentStep === AIRPURGE_REPORT_STEP+1 && reportMode === "PRESSURE" ) {
          setCurrentStep((currentStep) => currentStep - 1);
        }
        else{
          setCurrentStep((currentStep)=> currentStep+1);
        }        
        break;
      case 'RETURN':
              setCurrentStep((currentStep)=> currentStep-1);
        break;
        case 'DOWN':
          {
            if (currentStep === WARNING_AIRPURGE_STEP+1) {
              switchToPreviousElement(airPurgeActiveButtons,airPurgeActiveButton,setAirPurgeActiveButton)
            }
            if (currentStep === AIRPURGE_REPORT_STEP) {
              switchToNextElement(reportModes,reportMode,setReportMode)
            }
          }
          break;
        case 'UP':
          {
            if (currentStep === WARNING_AIRPURGE_STEP+1) {
              switchToNextElement(airPurgeActiveButtons,airPurgeActiveButton,setAirPurgeActiveButton)
            }
            if (currentStep === AIRPURGE_REPORT_STEP) {
              switchToPreviousElement(reportModes,reportMode,setReportMode)
            }
          }
          break;
        case 'MENU':
          {
            setSelectedScenario?.(Scenarios?.ScenarioMenuPAC);
          }
          break;
      default:
        break;
    }
  };

    // Call handleButtonClick when the clickedButton prop changes
    useEffect(() => {
        handleButtonClick(clickedButton);
    }, [clickedButton]);

        return (<>
        <div className={` ${showLoadingSpinner ? '' :'hidden' }  `}>
          <LoadingSpinner/>
        </div>
        
        <div className= {` ${showLoadingSpinner ? 'hidden':'' } text-white `}>

        <div className="flex justify-between items-center text-xs font-thin ">
            <div className="ml-2">
              <FaWifi />
            </div>
            <h1 className="text-[14px] pl-7 flex-grow text-center">
                {scenarioTitle}
            </h1>
            <div className="mr-1">
              <CiCloudOn className="inline-block w-4 h-4" />24 <sup>°c</sup>
            </div>
        </div>

            <div className={`h-[17.5rem] w-full overflow-y-auto ${styles['scrollable-container']}`}  ref={scrollableContainerRef}>
                {renderStep()}
            </div>


      </div>
        </>
        )

}

export default  ScenarioAccueilPAC;

