const LoadingSpinner: React.FC = () => {
    return (
      <div  className="  ">
      <div className="bg-black h-[16.5rem] w-[200px]   m-2 flex justify-center items-center">
        <div className="  flex justify-center items-center rounded-full">
        <img
                  className='w-10 inline'
                  src={`images/Loading-spinner.webp`}
                  alt=''
                />        
        </div>
      </div>
      </div>
    );
  };
  
  export default LoadingSpinner;
  