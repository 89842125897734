import React from "react";

const BarAnimationFinished: React.FC<{animationStateMessage: string}> = ({animationStateMessage}) => {

 

  return (
    <div className=" ">
        <div className="bg-[#222526] h-[8.5rem]  m-2 flex">
            <div className="text-[11px] p-2 text-[#C3CBD9]">
                <div className="w-[13rem] m-3 my-2 bg-[#686C73]  h-4">
                <div className="  bg-[#C3CBD9] h-4" style={{ width: `100%` }} />
                </div>
                <div>00:00</div>
                <div>
                  {animationStateMessage}
                </div>
                <div className='bg-[#C3CBD9] text-[#222526] text-[11px] inline py-2 '>
                    <div className=' inline-block w-32 my-6'>Terminer</div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default BarAnimationFinished;
