import React from 'react';
import Time from './Time';
import DateSelection from './DateSelection';

type TimeSelectionProps = {
  currentStep: number;
  daySelection: string;
  monthSelection: string;
  yearSelection: string;
  hourSelection: string;
  minuteSelection: string;
};

const TimeSelection: React.FC<TimeSelectionProps> = ({
  currentStep,
  daySelection,
  monthSelection,
  yearSelection,
  hourSelection,
  minuteSelection,
}) => (
  <>
     <div className='bg-[#222526] mx-1 pb-3 mb-1 px-2'>
      <p className='text-left text-[10px] py-1 text-[#C3CBD9] font-lato'>Date</p>
      <div className='flex items-start'>        
        <DateSelection isActive={currentStep === 2} label='Day' selection={daySelection} customClass='' customWidth='w-12' />
        <DateSelection isActive={currentStep === 3} label='Month' selection={monthSelection} customClass='mx-2' customWidth='w-24' />
        <DateSelection isActive={currentStep === 4} label='Year' selection={yearSelection} customClass='' customWidth='w-16' />
      </div>
    </div>


    <div className='bg-[#222526] mx-1 pb-3 mb-1 px-2'>
      <p className='text-left text-[10px] py-1 text-[#C3CBD9] font-lato'>Heure / Minute</p>
      <div className='flex items-start'>
        <Time isActive={currentStep === 5} label='Hour' selection={hourSelection} />
          <p className='mx-1 text-[#C3CBD9]'> : </p>
        <Time isActive={currentStep === 6} label='Minute' selection={minuteSelection} />
      </div>
    </div>
  </>
);

export default TimeSelection;
