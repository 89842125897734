import { AiOutlineCheck } from 'react-icons/ai';
const TemperaturePopUp: React.FC<{title:string, index:number}> = ({title, index}) => {
    return (
      <div  className=" relative z-10 mt-3">
      <div className="bg-[#3a3c40] h-[16.5rem] w-[200px]   m-2 flex flex-col  items-center">
        <div className=" mt-4">
            <div> {index}- {title} </div> 
            <img className=' mx-auto mt-12 mb-6 w-16' src={`images/Thermostat.svg`} alt='' /> 
        <p className=" text-sm"> Température modifiable <br/> sur thermostat<br/> d'ambiance</p> 
        </div>
      </div>
      </div>
    );
  };
  
  export default TemperaturePopUp;
  