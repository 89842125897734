import React from 'react';


import { Scenarios } from '../shared/types';

interface ImageBoxProps {
  imageSrc: string;
  name: string;
  type: string;
  easyStartId: Scenarios;
  menuId: Scenarios;
  setSelectedScenario: (boxId: Scenarios | null) => void;

}
const ImageBox: React.FC<ImageBoxProps> = ({ imageSrc, name, type, easyStartId ,menuId ,setSelectedScenario}) => {
  return (
    <div className='container justify-center items-center mx-auto max-w-[700px] my-4'>

      <div className="grid grid-cols-2 items-center bg-orange-50  p-6" >
        <img
          src={imageSrc}
          alt="Box"
          className="w-64 h-64"
        />
        <div className=' flex flex-col items-center'>
          <h1 className="mt-4  text-center text-3xl font-semibold text-[#ed725c]">{name}</h1>
          <p className="my-4 text-center text-[#A9A1A0]">{type}</p>

          <button className='bg-[#ed725c] px-4 my-3 w-48 text-white' onClick={()=>setSelectedScenario(easyStartId)} >Démarrage <br/> Easy Start</button>
          <button className='text-[#A9A1A0] text-xs underline decoration-solid' onClick={()=>setSelectedScenario(menuId)} > Aller au menu principal</button>
        </div>
      </div>
    </div>
  );
};

export default ImageBox;
