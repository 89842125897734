import { FiCheckCircle } from 'react-icons/fi';
const ValidationScreenPAC: React.FC = () => {
    return (
      <div  className=" relative z-10 ">
      <div className="bg-[#3a3c40] h-[16.5rem] w-[205px]   m-2 flex justify-center items-center">
        <div className="  flex justify-center items-center rounded-full">
          <FiCheckCircle className="inline m-2 text-[#a0a7b3] text-9xl " />
        </div>
      </div>
      </div>
    );
  };
  
  export default ValidationScreenPAC;
  