import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Scenarios, ScreenScenarioProps } from '../../../../../shared/types';
import { LuChevronsUpDown } from "react-icons/lu";
import { AiFillFire } from "react-icons/ai";
import TestCombustionList from './components/TestCombustionList';
import ProgressBar from '../../../../../shared/components/ProgressBar';
import styles from '../../../../../styles/ScenarioTestCombustion.module.css';
import ReportTestCombustion from './components/ReportTestCombustion';

type ScrollableContainerRef = React.RefObject<HTMLDivElement>;

const ScenarioTestCombustion: React.FC<ScreenScenarioProps> = ({ 
  clickedButton, 
  changeActiveButtons,  
  handleAlertMessage ,
  setSelectedScenario ,
  seed
}) => {
  const scrollableContainerRef: ScrollableContainerRef = useRef<HTMLDivElement>(null);
  const [currentStep, setCurrentStep] = useState(seed!);
  const [showReportTestCombustion, setShowReportTestCombustion] = useState(false);

  const [burnerModulation, setBurnerModulation] = useState("Arrêt"); 
  const [valvePosition, setValvePosition] = useState("Chauffage"); 
  const [startTemperature, setStartTemperature] = useState("38°C"); 
  const [returnTemperature, setReturnTemperature] = useState("28°C"); 
  const [ionisationCurrent, setIonisationCurrent] = useState("14 μA"); 
  const [ventilatorSpeed, setVentilatorSpeed] = useState("4380RPM"); 
  const [progressBar, setProgressBar] = useState(100);

 
  const burnerModulations = ["Arrêt","10%","20%","30%","40%",
  "50%","60%","Max CH 66%","70%","80%","90%","100%"]

  const valvePositionSelections = ['Chauffage','ECS']


  
  const handleButtonClick = (button: string | null) => {
    switch (button) {
      case 'B2':
        if (currentStep === 2) {
          const currentIndex = burnerModulations.indexOf(burnerModulation);
          if(currentIndex === burnerModulations.length-1) {break;}
          const nextIndex = (currentIndex + 1);
          setBurnerModulation(burnerModulations[nextIndex]);
        } 
        else if (currentStep === 4 ) {
          const currentIndex = valvePositionSelections.indexOf(valvePosition);
          const nextIndex = (currentIndex + 1) % valvePositionSelections.length;
          setValvePosition(valvePositionSelections[nextIndex]);
        }
        else {
          setCurrentStep((currentStep)=> currentStep-2);
        }
        break;
      case 'B4':
        
        if (currentStep === 2) {
          const currentIndex = burnerModulations.indexOf(burnerModulation);
          if(currentIndex === 0) {break;}
          const prevIndex = (currentIndex - 1 );
          setBurnerModulation(burnerModulations[prevIndex]);
        } 
        else if (currentStep === 4 ) {
          const currentIndex = valvePositionSelections.indexOf(valvePosition);
          const prevIndex = (currentIndex - 1 + valvePositionSelections.length) % valvePositionSelections.length;
          setValvePosition(valvePositionSelections[prevIndex]);
        }else{
          setCurrentStep((currentStep)=> currentStep+2);
        }
        break;

      case 'B5':
        // setCurrentStep((currentStep)=> {
        //   return currentStep-1
        // }
        // );
        break;
      case 'B6':
          if(currentStep ===2 || currentStep ===4){
            setCurrentStep((currentStep)=> currentStep-1);
          } else if(currentStep ===1 || currentStep ===3){
            setCurrentStep((currentStep)=> currentStep+1);
          }
          else if(currentStep ===13){
            setCurrentStep((currentStep)=> currentStep+1);
          }
          if(currentStep ===15){
            setSelectedScenario?.(Scenarios?.ScenarioMenu);
          }
        break;
      case 'B7':
        if(currentStep ===15){
          setSelectedScenario?.(Scenarios?.ScenarioMenu);
        }
        break;
      default:
        break;
    }
  }; 
  // Call handleButtonClick when the clickedButton prop changes
  React.useEffect(() => {
    handleButtonClick(clickedButton);
  }, [clickedButton]);

  useEffect(() => {
// ***************************** ProgressBar ***********************
    updateProgressBar();
// ***************************** Handle Validation TIME ************
    validateStepWithDelay(currentStep);
    if(currentStep==15){
      setShowReportTestCombustion(true);
    }
  }, [currentStep]);

  useEffect(() => {
    switch (currentStep) {
      case 1:
        handleActiveButtons(['B4', 'B6']);
        handleAlertMessage(true,"Le test de combustion peut aussi être fait sur l’ECS lorsqu’il n’y a pas de besoin de chauffage ou que l'émetteur est un plafond chauffant.");
        break;
      case 2:
        handleActiveButtons(['B2', 'B4',  'B6']);
        handleAlertMessage(true,"Le test de combustion peut aussi être fait sur l’ECS lorsqu’il n’y a pas de besoin de chauffage ou que l'émetteur est un plafond chauffant.");
        break;
      case 3:
        handleActiveButtons(['B2', 'B4',  'B6']);
        handleAlertMessage(true,"Le test de combustion peut aussi être fait sur l’ECS lorsqu’il n’y a pas de besoin de chauffage ou que l'émetteur est un plafond chauffant.");
        break;
      case 4:
        handleActiveButtons(['B2', 'B4',  'B6']);
        handleAlertMessage(true,"Le test de combustion peut aussi être fait sur l’ECS lorsqu’il n’y a pas de besoin de chauffage ou que l'émetteur est un plafond chauffant.");
        break;
      case 5:
        handleActiveButtons(['B2', 'B4',  'B6']);
        handleAlertMessage(true,"Le test de combustion peut aussi être fait sur l’ECS lorsqu’il n’y a pas de besoin de chauffage ou que l'émetteur est un plafond chauffant.");
        break;
      case 6:
        handleActiveButtons(['B2', 'B4',  'B6']);
        handleAlertMessage(true,"Le test de combustion peut aussi être fait sur l’ECS lorsqu’il n’y a pas de besoin de chauffage ou que l'émetteur est un plafond chauffant.");
        break;
      case 7:
        handleActiveButtons(['B2', 'B4',  'B6']);
        handleAlertMessage(true,"Le test de combustion peut aussi être fait sur l’ECS lorsqu’il n’y a pas de besoin de chauffage ou que l'émetteur est un plafond chauffant.");
        break;
      case 8:
        handleActiveButtons(['B2', 'B4',  'B6']);
        handleAlertMessage(true,"Le test de combustion peut aussi être fait sur l’ECS lorsqu’il n’y a pas de besoin de chauffage ou que l'émetteur est un plafond chauffant.");
        break;
      case 9:
        handleActiveButtons(['B2', 'B4',  'B6']);
        handleAlertMessage(true,"Le test de combustion peut aussi être fait sur l’ECS lorsqu’il n’y a pas de besoin de chauffage ou que l'émetteur est un plafond chauffant.");
        break;
      case 10:
        handleActiveButtons(['B2', 'B4',  'B6']);
        handleAlertMessage(true,"Le test de combustion peut aussi être fait sur l’ECS lorsqu’il n’y a pas de besoin de chauffage ou que l'émetteur est un plafond chauffant.");
        break;
      case 11:
        handleActiveButtons(['B2', 'B4',   'B6']);
        handleAlertMessage(true,"Le test de combustion peut aussi être fait sur l’ECS lorsqu’il n’y a pas de besoin de chauffage ou que l'émetteur est un plafond chauffant.");
        break;
      case 12:
        handleActiveButtons(['B2', 'B4',  'B6']);
        handleAlertMessage(true,"Le test de combustion peut aussi être fait sur l’ECS lorsqu’il n’y a pas de besoin de chauffage ou que l'émetteur est un plafond chauffant.");
        break;
      case 13:
        handleActiveButtons(['B2',  'B6']);
        handleAlertMessage(true,"Le test de combustion peut aussi être fait sur l’ECS lorsqu’il n’y a pas de besoin de chauffage ou que l'émetteur est un plafond chauffant.");
        break;
      case 14:
        handleActiveButtons([]);
        handleAlertMessage(false);
        break;
      case 15:
        handleActiveButtons(['B1', 'B3', 'B6', 'B7']);
        handleAlertMessage(true," Il s’agit de l'écran d’accueil de la chaudière qui indique son état de fonctionnement.");
        break;
      default:
        break;
    }
  },[currentStep])

  const updateProgressBar = () => {
    switch (currentStep) {
      case 1:
        setProgressBar(100);
        break;
      default:
        break;
    }
  };
  const stepValidationDelayMap : {[step: number]:  { nextStep: number; delay: number }} = {
    14: { nextStep: 15, delay: 2000 },
  };
  const validateStepWithDelay = (step: number) => {
    const stepData = stepValidationDelayMap[step];
    if (stepData && stepData.nextStep !== undefined && stepData.delay !== undefined) {
      const { nextStep, delay } = stepData;
      const timeout = setTimeout(() => {
        setCurrentStep(nextStep);
      }, delay);
      return () => clearTimeout(timeout);
    }
  };
  const updateScrollBar = (scrollPosition :number) => {
    if (scrollableContainerRef.current) {
      const containerHeight = scrollableContainerRef.current.scrollHeight - scrollableContainerRef.current.clientHeight;
      const scrollY = (scrollPosition / 100) * containerHeight;
      scrollableContainerRef.current.scrollTo(0, scrollY);
    }
  };
  function handleActiveButtons(buttons: string[]) {
    changeActiveButtons(buttons);
  }

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        updateScrollBar(0)
        console.log("[TEST COMBUSTION] Render case1")
        return <TestCombustionList
        burnerModulation={burnerModulation}
        valvePosition={valvePosition}
        startTemperature={startTemperature}
        returnTemperature={returnTemperature}
        ionisationCurrent={ionisationCurrent}
        ventilatorSpeed={ventilatorSpeed}
        currentStep={currentStep}

      />;
      case 2:
        updateScrollBar(0)
        console.log("[TEST COMBUSTION] Render case1")
        return <TestCombustionList
        burnerModulation={burnerModulation}
        valvePosition={valvePosition}
        startTemperature={startTemperature}
        returnTemperature={returnTemperature}
        ionisationCurrent={ionisationCurrent}
        ventilatorSpeed={ventilatorSpeed}
        currentStep={currentStep}
      />;
      case 3:
        updateScrollBar(0)
        return <TestCombustionList
        burnerModulation={burnerModulation}
        valvePosition={valvePosition}
        startTemperature={startTemperature}
        returnTemperature={returnTemperature}
        ionisationCurrent={ionisationCurrent}
        ventilatorSpeed={ventilatorSpeed}
        currentStep={currentStep}
      />;
      case 4:
        updateScrollBar(0)
        return <TestCombustionList
        burnerModulation={burnerModulation}
        valvePosition={valvePosition}
        startTemperature={startTemperature}
        returnTemperature={returnTemperature}
        ionisationCurrent={ionisationCurrent}
        ventilatorSpeed={ventilatorSpeed}
        currentStep={currentStep}
      />;
      case 5:
        updateScrollBar(0)
        return <TestCombustionList
        burnerModulation={burnerModulation}
        valvePosition={valvePosition}
        startTemperature={startTemperature}
        returnTemperature={returnTemperature}
        ionisationCurrent={ionisationCurrent}
        ventilatorSpeed={ventilatorSpeed}
        currentStep={currentStep}
      />;
      case 6:
        updateScrollBar(0)
        return <TestCombustionList
        burnerModulation={burnerModulation}
        valvePosition={valvePosition}
        startTemperature={startTemperature}
        returnTemperature={returnTemperature}
        ionisationCurrent={ionisationCurrent}
        ventilatorSpeed={ventilatorSpeed}
        currentStep={currentStep}
      />;
      case 7:
        updateScrollBar(68)
        return <TestCombustionList
        burnerModulation={burnerModulation}
        valvePosition={valvePosition}
        startTemperature={startTemperature}
        returnTemperature={returnTemperature}
        ionisationCurrent={ionisationCurrent}
        ventilatorSpeed={ventilatorSpeed}
        currentStep={currentStep}
      />;
      case 8:
        updateScrollBar(68)
        return <TestCombustionList
        burnerModulation={burnerModulation}
        valvePosition={valvePosition}
        startTemperature={startTemperature}
        returnTemperature={returnTemperature}
        ionisationCurrent={ionisationCurrent}
        ventilatorSpeed={ventilatorSpeed}
        currentStep={currentStep}
      />;
      case 9:
        updateScrollBar(68)
        return <TestCombustionList
        burnerModulation={burnerModulation}
        valvePosition={valvePosition}
        startTemperature={startTemperature}
        returnTemperature={returnTemperature}
        ionisationCurrent={ionisationCurrent}
        ventilatorSpeed={ventilatorSpeed}
        currentStep={currentStep}
      />;
      case 10:
        updateScrollBar(68)
        return <TestCombustionList
        burnerModulation={burnerModulation}
        valvePosition={valvePosition}
        startTemperature={startTemperature}
        returnTemperature={returnTemperature}
        ionisationCurrent={ionisationCurrent}
        ventilatorSpeed={ventilatorSpeed}
        currentStep={currentStep}
      />;
      case 11:
        updateScrollBar(68)
        return <TestCombustionList
        burnerModulation={burnerModulation}
        valvePosition={valvePosition}
        startTemperature={startTemperature}
        returnTemperature={returnTemperature}
        ionisationCurrent={ionisationCurrent}
        ventilatorSpeed={ventilatorSpeed}
        currentStep={currentStep}
      />;
      case 12:
        updateScrollBar(68)
        return <TestCombustionList
        burnerModulation={burnerModulation}
        valvePosition={valvePosition}
        startTemperature={startTemperature}
        returnTemperature={returnTemperature}
        ionisationCurrent={ionisationCurrent}
        ventilatorSpeed={ventilatorSpeed}
        currentStep={currentStep}
      />;
      case 13:
        // show valider button
        updateScrollBar(100)
        return <TestCombustionList
        burnerModulation={burnerModulation}
        valvePosition={valvePosition}
        startTemperature={startTemperature}
        returnTemperature={returnTemperature}
        ionisationCurrent={ionisationCurrent}
        ventilatorSpeed={ventilatorSpeed}
        currentStep={currentStep}
      />;
      case 14:
        // show validation
        updateScrollBar(100)
        return <TestCombustionList
        burnerModulation={burnerModulation}
        valvePosition={valvePosition}
        startTemperature={startTemperature}
        returnTemperature={returnTemperature}
        ionisationCurrent={ionisationCurrent}
        ventilatorSpeed={ventilatorSpeed}
        currentStep={currentStep}
      />;
      case 15:
        updateScrollBar(100)
        return <ReportTestCombustion/>;
      default:
        return null;
    }
  };

  return (
    <>
      {showReportTestCombustion? (
        <ReportTestCombustion />
      ):
      <div className=" text-white ">
          <div className="flex items-center mx-2">
          <img
                  className='w-[6px] inline'
                  src="images/fire.svg"
                  alt=''
                /> 
              <h1 className="text-[10px] mx-auto">Test Combustion</h1>
          </div>
        <hr className="border-[#686c73] border-1 mx-1" /> 
        <div className={` h-[9.5rem] w-full overflow-y-auto ${styles['scrollable-container']}`}  ref={scrollableContainerRef}>
            {renderStep()}
        </div>
          <ProgressBar progress={progressBar} />
      </div>
      }
      
    </>
  );
};

export default ScenarioTestCombustion;


