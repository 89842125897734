export enum Scenarios {
    ScenarioEasyStart = "ScenarioEasyStart",
    ScenarioTestCombustion = "ScenarioTestCombustion",
    ScenarioMenu = "ScenarioMenu",

    ScenarioEasyStartPAC = "ScenarioEasyStartPAC",
    ScenarioMenuPAC = "ScenarioMenuPAC",
    ScenarioAccueilPAC = "ScenarioAccueilPAC",

}


export const ScenariosInfo = {
  [Scenarios.ScenarioEasyStart]: {
    type: "Naema 3 / Effinox 2",
  },
  [Scenarios.ScenarioTestCombustion]: {
    type: "Naema 3 / Effinox 2",
  },
  [Scenarios.ScenarioMenu]: {
    type: "Naema 3 / Effinox 2",
  },
  [Scenarios.ScenarioEasyStartPAC]: {
    type: "Alfea Excellia S / Alfea M / Hybea",
  },
  [Scenarios.ScenarioMenuPAC]: {
    type: "Alfea Excellia S / Alfea M / Hybea",
  },
  [Scenarios.ScenarioAccueilPAC]: {
    type: "Alfea Excellia S / Alfea M / Hybea",
  },
};

export enum WarningAirPurgeMode {
  AirPurgeMessage,
  ValidationScreen,
  Countdown,
  None // Use this for default case
}


export interface ScreenScenarioProps {
  clickedButton: string | null;
  seed?: number;
  changeActiveButtons:(newArray: string[]) => void;
  handleAlertMessage:(flag: boolean, message?: string) => void;
  handleAlertClick?:() => void;
  setSelectedScenario?: React.Dispatch<React.SetStateAction<Scenarios | null>>
  setWheelShadowColor?:React.Dispatch<React.SetStateAction<string>>;
  setSeed?:React.Dispatch<React.SetStateAction<number>>;
}
