import { useEffect, useRef, useState } from "react";
import { Scenarios, ScreenScenarioProps } from "../../../../../shared/types"
import { FaFlag } from "react-icons/fa";
import LanguageSelectionPAC from "./components/LanguageSelectionPAC";
import styles from '../../../../../styles/ScenarioEasyStartPAC.module.css';
import WelcomeMessagePAC from "./components/WelcomeMessagePAC";
import TimeSelectionPAC from "./components/TimeSelectionPAC";
import InstallationSelectionPAC from "./components/InstallationSelectionPAC";
import { switchToNextElement, switchToPreviousElement } from "../../../../../shared/utils";



type ScrollableContainerRef = React.RefObject<HTMLDivElement>;


const ScenarioEasyStartPAC: React.FC<ScreenScenarioProps> = ({ 
    clickedButton, 
    changeActiveButtons, 
    handleAlertMessage ,
    setSelectedScenario ,
    handleAlertClick})=>{

    const LANGUAGE_START_STEP = 1
    const TIME_START_STEP = 3
    const INSTALATION_START_STEP = 9
    
    const [currentStep, setCurrentStep] = useState(1);
    const [language, setLanguage] = useState("Français");
    const [scenarioTitle, setScenarioTitle] = useState<JSX.Element>(<FaFlag className="inline-block" />);
    const [daySelection, setDaySelection] = useState('01');
    const [monthSelection, setMonthSelection] = useState('Janvier');
    const [yearSelection, setYearSelection] = useState('2024');
    const [hourSelection, setHourSelection] = useState('09');
    const [minuteSelection, setMinuteSelection] = useState('00');
    const [seasonSelection, setSeasonSelection] = useState('En automatique');

    const [uniteExtSelection, setUniteExtSelection] = useState('-- kW');
    const [easyStartFinishedButton, setEasyStartFinishedButton] = useState('register');


    const languages = ['Eesti', 'English', 'Español', 'Ελληνικά', 'Français','Italiano', 'Lietuvių k.', 'Nederlands']; 
    const daySelections = ['01', '02','03']; 
    const monthSelections = ['Janvier', 'Février','Mars']; 
    const yearSelections = ['2024','2025','2026']; 
    const hourSelections = ['09', '10','11'];
    const minuteSelections = ['00','01', '02'];
    const seasonSelections = ['En automatique', 'Manuelle'];

    const uniteExtSelections = ['-- kW', '10 kW', '12 kW', '17 kW'];
    const easyStartFinishedButtons = ['register','verify']; 



    const scrollableContainerRef: ScrollableContainerRef = useRef<HTMLDivElement>(null);
    
    const updateScrollBar = (scrollPosition :number) => {
      if (scrollableContainerRef.current) {
        const containerHeight = scrollableContainerRef.current.scrollHeight - scrollableContainerRef.current.clientHeight;
        const scrollY = (scrollPosition / 100) * containerHeight;
        scrollableContainerRef.current.scrollTo(0, scrollY);
      }
    };
  
//   **********  Handle ScenarioTitle, Active buttons and astuce message
    useEffect(() => {
        switch (currentStep) {
          case LANGUAGE_START_STEP:
            changeActiveButtons(['OK', 'UP','DOWN']);
            handleAlertMessage(true,"Si nécessaire, cliquez sur l'écran pour activer le défilement de la page. Cliquez ensuite sur la molette pour figer le défilement.");
            handleAlertClick!()
            break;
          case LANGUAGE_START_STEP+1:
            changeActiveButtons(['OK','RETURN']);
            handleAlertMessage(false,"");
            break;
          case TIME_START_STEP:
            changeActiveButtons(['OK','RETURN', 'UP','DOWN']);
            handleAlertMessage(false,"");
            setScenarioTitle(<div className="text-[#C3CBD9]">Date / Heure</div>);
            break;
          case INSTALATION_START_STEP:
            handleAlertMessage(true,"D'autres types d'émetteurs peuvent être sélectionnés (plancher, radiateurs), et il est possible de configurer l'installation sur un seul circuit.");
            if(uniteExtSelection !=='-- kW'){
              changeActiveButtons(['OK','RETURN', 'UP','DOWN']);
            }else{
              changeActiveButtons(['RETURN', 'UP','DOWN']);
            }
            setScenarioTitle(<div className="text-[#C3CBD9]">Installation</div>);
            break;
          case INSTALATION_START_STEP+1:
            setUniteExtSelection("12 kW");
            break;
          case INSTALATION_START_STEP+10:
            handleAlertMessage(false,"");
            break;

  

          default:
            break;
        }
          }, [currentStep,uniteExtSelection]);

//   **********  Handle rendered Step
    const renderStep = () => {
        switch (currentStep) {
          case LANGUAGE_START_STEP:
            if(language==="Nederlands")
            {
              updateScrollBar(100)
            }else{
              updateScrollBar(0)
            }

            return <LanguageSelectionPAC language={language} welcomeMessage={false} />;
          case LANGUAGE_START_STEP+1 :
            return <LanguageSelectionPAC language={language} welcomeMessage={true} />;
          case TIME_START_STEP:
            return <TimeSelectionPAC 
            label={'Day'}
            daySelection={daySelection}
            monthSelection={monthSelection}
            yearSelection={yearSelection}
            hourSelection={hourSelection}
            minuteSelection={minuteSelection}
            seasonSelection={seasonSelection}
            />;
          case TIME_START_STEP+1:
            return <TimeSelectionPAC 
            label={'Month'}
            daySelection={daySelection}
            monthSelection={monthSelection}
            yearSelection={yearSelection}
            hourSelection={hourSelection}
            minuteSelection={minuteSelection}
            seasonSelection={seasonSelection}
            />;
          case TIME_START_STEP+2:
            return <TimeSelectionPAC 
            label={'Year'}
            daySelection={daySelection}
            monthSelection={monthSelection}
            yearSelection={yearSelection}
            hourSelection={hourSelection}
            minuteSelection={minuteSelection}
            seasonSelection={seasonSelection} 
            />;
          case TIME_START_STEP+3:
            return <TimeSelectionPAC 
            label={'Hour'}
            daySelection={daySelection}
            monthSelection={monthSelection}
            yearSelection={yearSelection}
            hourSelection={hourSelection}
            minuteSelection={minuteSelection}
            seasonSelection={seasonSelection}
            />;
          case TIME_START_STEP+4:
            return <TimeSelectionPAC 
            label={'Minute'}
            daySelection={daySelection}
            monthSelection={monthSelection}
            yearSelection={yearSelection}
            hourSelection={hourSelection}
            minuteSelection={minuteSelection}
            seasonSelection={seasonSelection}
            />;
          case TIME_START_STEP+5:
            return <TimeSelectionPAC 
            label={'Season'}
            daySelection={daySelection}
            monthSelection={monthSelection}
            yearSelection={yearSelection}
            hourSelection={hourSelection}
            minuteSelection={minuteSelection}
            seasonSelection={seasonSelection} 
            />;
          case INSTALATION_START_STEP:
            updateScrollBar(0)
            return <InstallationSelectionPAC 
            label={'UniteExt'}
            UniteExtSelection={uniteExtSelection}
            instalationfinished={false}
            easyStartFinishedButton={easyStartFinishedButton}
            />;
          case INSTALATION_START_STEP+1:
            return <InstallationSelectionPAC 
            label={'EauChaudeExterne'}
            UniteExtSelection={uniteExtSelection}
            instalationfinished={false}
            easyStartFinishedButton={easyStartFinishedButton}
            />;
          case INSTALATION_START_STEP+2:
            return <InstallationSelectionPAC 
            label={'AppointChauffage'}
            UniteExtSelection={uniteExtSelection}
            instalationfinished={false}
            easyStartFinishedButton={easyStartFinishedButton}
            />;
          case INSTALATION_START_STEP+3:
            return <InstallationSelectionPAC 
            label={'NombreCircuits'}
            UniteExtSelection={uniteExtSelection}
            instalationfinished={false}
            easyStartFinishedButton={easyStartFinishedButton}
            />;
          case INSTALATION_START_STEP+4:
            updateScrollBar(0)
            return <InstallationSelectionPAC 
            label={'Circuit1Nom'}
            UniteExtSelection={uniteExtSelection}
            instalationfinished={false}
            easyStartFinishedButton={easyStartFinishedButton}
            />;
          case INSTALATION_START_STEP+5:
            updateScrollBar(100)
            return <InstallationSelectionPAC 
            label={'Circuit1Type'}
            UniteExtSelection={uniteExtSelection}
            instalationfinished={false}
            easyStartFinishedButton={easyStartFinishedButton}
            />;
          case INSTALATION_START_STEP+6:
            return <InstallationSelectionPAC 
            label={'Circuit1Confort'}
            UniteExtSelection={uniteExtSelection}
            instalationfinished={false}
            easyStartFinishedButton={easyStartFinishedButton}
            />;
          case INSTALATION_START_STEP+7:
            return <InstallationSelectionPAC 
            label={'Circuit2Nom'}
            UniteExtSelection={uniteExtSelection}
            instalationfinished={false}
            easyStartFinishedButton={easyStartFinishedButton}
            />;
          case INSTALATION_START_STEP+8:
            return <InstallationSelectionPAC 
            label={'Circuit2Type'}
            UniteExtSelection={uniteExtSelection}
            instalationfinished={false}
            easyStartFinishedButton={easyStartFinishedButton}
            />;
          case INSTALATION_START_STEP+9:
            return <InstallationSelectionPAC 
            label={'Circuit2Confort'}
            UniteExtSelection={uniteExtSelection}
            instalationfinished={false}
            easyStartFinishedButton={easyStartFinishedButton}
            />;
          case INSTALATION_START_STEP+10:
            return <InstallationSelectionPAC 
            label={'Circuit2Confort'}
            UniteExtSelection={uniteExtSelection}
            instalationfinished={true}
            easyStartFinishedButton={easyStartFinishedButton}
            />;
          case INSTALATION_START_STEP+11:
            return <InstallationSelectionPAC 
            label={'Circuit2Confort'}
            UniteExtSelection={uniteExtSelection}
            instalationfinished={false}
            easyStartFinishedButton={easyStartFinishedButton}
            validationScreen={true}
            />;
          default:
            return null;
        }
    }


//   **********  Handle buttons click

  const handleButtonClick = (button: string | null) => {
    
    switch (button) {
      case 'OK':
          
          if (currentStep === INSTALATION_START_STEP+10 && easyStartFinishedButton=="verify") {
              setCurrentStep(INSTALATION_START_STEP);
            }
          else if (currentStep === INSTALATION_START_STEP+10 && easyStartFinishedButton=="register") {
              setCurrentStep((currentStep) => currentStep + 1);
              setTimeout(() => {
                setSelectedScenario?.(Scenarios?.ScenarioAccueilPAC);
              }, 1000); 
            
            }
          else{
            setCurrentStep((currentStep)=> currentStep+1);
          }
      break;  
      case 'RETURN':
          if (currentStep === TIME_START_STEP){
            setCurrentStep((currentStep)=> currentStep-2);
          }else{
            setCurrentStep((currentStep)=> currentStep-1);
          }
      break;
      case 'DOWN':
        {
          if (currentStep === LANGUAGE_START_STEP) {
            switchToNextElement(languages,language,setLanguage)
          }
          if (currentStep === TIME_START_STEP) {
            switchToNextElement(daySelections, daySelection, setDaySelection);
          }
          if (currentStep === TIME_START_STEP + 1) {
            switchToNextElement(monthSelections, monthSelection, setMonthSelection);
          }
          if (currentStep === TIME_START_STEP + 2) {
            switchToNextElement(yearSelections, yearSelection, setYearSelection);
          }
          if (currentStep === TIME_START_STEP + 3) {
            switchToNextElement(hourSelections, hourSelection, setHourSelection);
          }
          if (currentStep === TIME_START_STEP + 4) {
            switchToNextElement(minuteSelections, minuteSelection, setMinuteSelection);
          }
          if (currentStep === TIME_START_STEP + 5) {
            switchToNextElement(seasonSelections, seasonSelection, setSeasonSelection);
          }
          if (currentStep === INSTALATION_START_STEP) {
            switchToNextElement(uniteExtSelections, uniteExtSelection, setUniteExtSelection);
          }
          if (currentStep === INSTALATION_START_STEP + 10) {
            switchToNextElement(easyStartFinishedButtons, easyStartFinishedButton, setEasyStartFinishedButton);
          }

        }
        
          
      break;
      case 'UP':
        {
          if (currentStep === LANGUAGE_START_STEP) {
            switchToPreviousElement(languages, language, setLanguage);
          }
          if (currentStep === TIME_START_STEP) {
            switchToPreviousElement(daySelections, daySelection, setDaySelection);
          }
          if (currentStep === TIME_START_STEP + 1) {
            switchToPreviousElement(monthSelections, monthSelection, setMonthSelection);
          }
          if (currentStep === TIME_START_STEP + 2) {
            switchToPreviousElement(yearSelections, yearSelection, setYearSelection);
          }
          if (currentStep === TIME_START_STEP + 3) {
            switchToPreviousElement(hourSelections, hourSelection, setHourSelection);
          }
          if (currentStep === TIME_START_STEP + 4) {
            switchToPreviousElement(minuteSelections, minuteSelection, setMinuteSelection);
          }
          if (currentStep === TIME_START_STEP + 5) {
            switchToPreviousElement(seasonSelections, seasonSelection, setSeasonSelection);
          }
          if (currentStep === INSTALATION_START_STEP + 10) {
            switchToPreviousElement(easyStartFinishedButtons, easyStartFinishedButton, setEasyStartFinishedButton);
          }
          
        }

      break;
      default:
        break;
    }
  };

    // Call handleButtonClick when the clickedButton prop changes
    useEffect(() => {
        handleButtonClick(clickedButton);
    }, [clickedButton]);

        return (<>
        
        <div className="text-white ">
            <div className="flex items-center justify-between w-full"> 
                <div className="bg-[#c2cbd9] px-1 m-1 rounded"> 
                    <div className="text-[7px] text-black">
                        Easy <br/> Start
                    </div>
                </div>
                <h1 className="text-[10px] absolute left-1/2 transform -translate-x-1/2"> 
                    {scenarioTitle}
                </h1>
            </div>

            <div className={`h-[17.5rem] w-full overflow-y-auto ${styles['scrollable-container']}`}  ref={scrollableContainerRef}>
                {renderStep()}
            </div>


      </div>
        </>
        )

}

export default  ScenarioEasyStartPAC;