import React from 'react';
import { LuChevronsUpDown } from 'react-icons/lu';

type ColumnSelectionProps = {
  title: string;
  value: string;
  state: 1 | 2 | 3;
};

const ColumnSelection: React.FC<ColumnSelectionProps> = ({ title, value, state }) => (
    <div className='mb-1 mx-1'>
    {state===1 ? (
    <div className={`flex justify-between items-center bg-[#c3cbd9] text-[#222526]`}>
        <div className="text-[10px] px-4 h-10 py-1 w-[45%] text-left ">{title}</div>
        <div className="text-[10px] pr-4 py-1 w-1/2 text-right ">{value}</div>
    </div>
    ) : state===2 ?(
        <div className={`flex justify-between items-center `}>
            <div className="text-[10px] px-4 h-10 py-1  w-[62%] text-left bg-[#45474D] text-[#C3CBD9] ">
                <div className='w-[65%]'>
                    {title}
                </div>
            </div>
            <div className="text-[10px] text-right  w-[38%] h-10 flex items-center  bg-[#C2CAD8] text-black
            border-l-2 border-r-solid border-black ">
                <div className="w-[20%]">

                <LuChevronsUpDown className="inline text-lg text-left " />
                </div>
                <div className="w-[63%]">

                    {value}
                </div>
            </div>
        </div>
    ):(
        <div className={`flex justify-between items-center bg-[#45474D] text-[#A0A7B3]`} >
            <div className="text-[10px] px-4 h-10 py-1 w-[45%] text-left ">{title}</div>
            <div className="text-[10px] px-3 py-1 w-1/2 text-right ">{value}</div>
      </div>
    )}
    </div>
);

export default ColumnSelection;
