

const MenuInstallPAC: React.FC<{menuElement:string}> = ({menuElement})=>{
    return (
        <>
        <div className=" mx-1 mb-2">

            <div className={`${(menuElement === 'SA' ) ? ' bg-[#C3CBD9]' : 'bg-[#45474D]'} flex justify-between items-center mt-1 `}>   
                <div className={`${(menuElement === 'SA' ) ? ' text-[#222526]' : 'text-[#C3CBD9]'} text-[14px] px-3 py-2 w-full text-cente `}>
                Services Actifs
                </div>
            </div>
            <div className={`${(menuElement === 'OI' ) ? ' bg-[#C3CBD9]' : 'bg-[#45474D]'} flex justify-between items-center mt-1 `}>   
                <div className={`${(menuElement === 'OI' ) ? ' text-[#222526]' : 'text-[#C3CBD9]'} text-[14px] px-3 py-2 w-full text-cente `}>
                Options Installées
                </div>
            </div>

            <div className={`${(menuElement === 'ECS' ) ? ' bg-[#C3CBD9]' : 'bg-[#45474D]'} flex justify-between items-center mt-1 `}>   
                <div className={`${(menuElement === 'ECS' ) ? ' text-[#222526]' : 'text-[#C3CBD9]'} text-[14px] px-3 py-2 w-full text-cente `}>
                Eau Chaude Sanitaire
                </div>
            </div>

            <div className={`${(menuElement === 'CF' ) ? ' bg-[#C3CBD9]' : 'bg-[#45474D]'} flex justify-between items-center mt-1 `}>   
                <div className={`${(menuElement === 'CF' ) ? ' text-[#222526]' : 'text-[#C3CBD9]'} text-[14px] px-3 py-2 w-full text-cente `}>
                Chauffage / Froid
                </div>
            </div>

            <div className={`${(menuElement === 'PAC' ) ? ' bg-[#C3CBD9]' : 'bg-[#45474D]'} flex justify-between items-center mt-1 `}>   
                <div className={`${(menuElement === 'PAC' ) ? ' text-[#222526]' : 'text-[#C3CBD9]'} text-[14px] px-3 py-2 w-full text-cente `}>
                Pompe à Chaleur
                </div>
            </div>
            
            <div className={`${(menuElement === 'FA' ) ? ' bg-[#C3CBD9]' : 'bg-[#45474D]'} flex justify-between items-center mt-1 `}>   
                <div className={`${(menuElement === 'FA' ) ? ' text-[#222526]' : 'text-[#C3CBD9]'} text-[14px] px-3 py-2 w-full text-cente `}>
                Fonctions Annexes
                </div>
            </div>
            
            <div className={`${(menuElement === 'RR' ) ? ' bg-[#C3CBD9]' : 'bg-[#45474D]'} flex justify-between items-center mt-1 `}>   
                <div className={`${(menuElement === 'RR' ) ? ' text-[#222526]' : 'text-[#C3CBD9]'} text-[14px] px-3 py-2 w-full text-cente `}>
                Réseau Radio
                </div>
            </div>

            <div className={`${(menuElement === 'DIAG' ) ? ' bg-[#C3CBD9]' : 'bg-[#45474D]'} flex justify-between items-center mt-1 `}>   
                <div className={`${(menuElement === 'DIAG' ) ? ' text-[#222526]' : 'text-[#C3CBD9]'} text-[14px] px-3 py-2 w-full text-cente `}>
                Diagnostic
                </div>
            </div>
        </div>
        </>
    )
}

export default MenuInstallPAC;