import { useEffect, useRef, useState } from "react";
import { Scenarios } from "../../../shared/types";
import styles from '../../../styles/RemoteControlPAC.module.css';
import ScenarioEasyStartPAC from "./scenarios/ScenarioEasyStartPAC/ScenarioEasyStartPAC";
import ScenarioMenuPAC from "./scenarios/ScenarioMenuPAC/ScenarioMenuPAC";
import { AiOutlineInfoCircle } from "react-icons/ai";
import AlertPAC from "../../../shared/components/AlertPAC";
import ScenarioAccueilPAC from "./scenarios/ScenarioAccueilPAC/ScenarioAccueilPAC";
import Wheel from "./wheel.tsx";

interface RemoteControlProps {
    selectedScenario: Scenarios;
    setSelectedScenario: React.Dispatch<React.SetStateAction<Scenarios | null>>
}

const RemoteControlPAC: React.FC<RemoteControlProps> = ({
    selectedScenario,
    setSelectedScenario,
    }) => {


    const [screenContent, setScreenContent] = useState<JSX.Element | null>(null);
    const [wheelShadowColor, setWheelShadowColor] = useState("white");
    const [clickedButton, setClickedButton] = useState<string | null>(null);
    const [activeButtons, setActiveButtons] = useState<string[]>([]); // Array to hold the names of enabled buttons
   
    const [showAstuce, setShowAstuce] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [touchAction, setTouchAction] = useState("")
    const [alertMessage, setAlertMessage] = useState('');
    const [seed, setSeed] = useState(1)


// ************************** ALERT MESSAGE *****************************************

  const handleAlertClick = () => {
    setShowAlert(a=> !a);
  };

  const handleShowAlert = () => {
    setShowAlert(true)
  }

// ************************** HANDLE BUTTONS *****************************************

    const handleButtonClick = (button: string) => {
      setClickedButton(button);
    };
    const handleButtonMenuClick = () => {
      handleButtonClick("MENU");
    };
    const handleButtonUpClick = () => {
      handleButtonClick("UP");
    };
    const handleButtonOkClick = () => {
      handleButtonClick("OK");
    };
    const handleButtonDownClick = () => {
      handleButtonClick("DOWN");
    };
    const handleButtonReturnClick = () => {
      handleButtonClick("RETURN");
    };

    
    const changeActiveButtons = (newArray:string[]) => {
      // Update the state in the parent component with the new array
      setActiveButtons(newArray);
    };
  
    const handleAlertMessage = (flag:boolean, message="") => {
      // Update the state in the parent component with the new Array
      setShowAstuce(flag);
      setAlertMessage(message);
    };

    const IHMRef = useRef<HTMLDivElement>(null);


    document.body.style.touchAction = touchAction

    useEffect(()=>{
      IHMRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    },[])

    useEffect(() => {
        switch (selectedScenario) {
          case Scenarios.ScenarioEasyStartPAC:
            setScreenContent(<ScenarioEasyStartPAC 
              setSelectedScenario={setSelectedScenario}
              clickedButton={clickedButton} 
              changeActiveButtons={changeActiveButtons} 
              handleAlertMessage={handleAlertMessage}
              handleAlertClick={handleShowAlert}
              />);
            break;
            case Scenarios.ScenarioAccueilPAC:
              setScreenContent(<ScenarioAccueilPAC 
                clickedButton={clickedButton}
                seed={seed}
                setSelectedScenario={setSelectedScenario}
                setWheelShadowColor={setWheelShadowColor}
                changeActiveButtons={changeActiveButtons} 
                handleAlertMessage={handleAlertMessage}
                handleAlertClick={handleAlertClick}
                />);
              break;
            case Scenarios.ScenarioMenuPAC:
              setScreenContent(<ScenarioMenuPAC 
                setSelectedScenario={setSelectedScenario}
                clickedButton={clickedButton} 
                changeActiveButtons={changeActiveButtons} 
                handleAlertMessage={handleAlertMessage}
                setSeed={setSeed} 
                />);
              break;
          default:
            setScreenContent(null);
            break;
        }
        return()=> setClickedButton(null);
      }, [selectedScenario, clickedButton]);
    
      //************************** Handle Animation *****************************************
      const [showAnimation, setShowAnimation] = useState(true);
      useEffect(() => {
        const timer = setTimeout(() =>{
          setShowAnimation(false);
        },100);
        return () => clearTimeout(timer);
      }, []);
    
    return (
    <div id="control-PAC" className={`relative ${styles['image-boitier']} z-10 ${showAnimation ? styles['hidden']:''  }  `}>

      <div className="w-[600px] grid grid-cols-6 m-0">
        

        <div className=" col-span-5 top-7  relative">
            <img src="images/Rectangle-modele.svg" alt="Cover Image" className={`${styles['image-boitier'] }`} />
            <div className= {` absolute inset-0 max-w-400 mt-20 `}>
              
              <div ref={IHMRef} className="grid grid-cols-4">
                {/* Screen Content */}
                <div className="col-start-2 col-span-2  h-80  bg-black border-solid border-r-4 border-b-4 border-[#707070] rounded "> {screenContent}</div>
              </div>

                {/* menu Button*/}
              <div className="grid grid-cols-7  mt-4 ">
                <div className="col-start-3 col-span-1"> 
                    <button id="menu-button" onClick={handleButtonMenuClick} disabled={!activeButtons.includes('MENU')}   className={` ${styles['rounded-button'] }`}>
                    <img
                        className='w-5 inline'
                        src={`images/menu-${activeButtons.includes('MENU') ? 'active' : 'inactive'}.svg`}
                        alt=''
                    />            
                    </button>
                </div>
              </div>
                {/* moulette*/}
                <div className="grid grid-cols-7">
                  <div className=' ml-32 w-48 h-48 min-w-48 min-h-48 relative overflow-hidden'>
                    <Wheel up={handleButtonUpClick} down={handleButtonDownClick} ok={handleButtonOkClick} activeButtons={activeButtons} wheelShadowColor={wheelShadowColor} setTouch={setTouchAction}/>
                  </div>
                </div>
                {/* return Button */}
              <div className="grid grid-cols-7">
                <div className="col-start-5 col-span-1"> 
                    <button id="return-button" onClick={handleButtonReturnClick} disabled={!activeButtons.includes('RETURN')}   className={` ${styles['rounded-button'] }`}>
                    <img
                        className='w-5 inline'
                        src={`images/return-${activeButtons.includes('RETURN') ? 'active' : 'inactive'}.svg`}
                        alt=''
                    />            
                    </button>
                </div>
              </div>


            </div>
        </div>

    
        <div id="astuce-button" className= {`col-span-1 relative ${showAstuce ? '' : 'hidden'}`} >
                  
          {showAlert && 
          <div className="z-50 absolute right-[17px] top-[60px] ">
            <AlertPAC  message={alertMessage} onClose={handleAlertClick} />
          </div>
          }
          <div className="flex justify-center">
            {/* relative left-[60px]  */}
            <div className=" bg-white drop-shadow-lg p-2 mb-2 rounded border-solid border-[#ED725C] border-2 ">
              <AiOutlineInfoCircle className="text-[#ED725C] inline text-3xl" onClick={handleAlertClick} />
            </div>
          </div>
        </div>
    </div>
    </div>

    )   
}

export default RemoteControlPAC;    