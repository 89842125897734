import React, { useEffect, useRef, useState } from 'react';
import { Scenarios, ScreenScenarioProps } from '../../../../../shared/types';
import styles from '../../../../../styles/ScenarioMenu.module.css';
import MenuList from './components/MenuList';
import ReportTestCombustion from '../ScenarioTestCombustion/components/ReportTestCombustion';

type ScrollableContainerRef = React.RefObject<HTMLDivElement>;

const ScenarioMenu: React.FC<ScreenScenarioProps> = ({ 
  clickedButton, 
  changeActiveButtons,  
  handleAlertMessage ,
  setSelectedScenario ,
  setSeed
}) => {
  const scrollableContainerRef: ScrollableContainerRef = useRef<HTMLDivElement>(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [scenarioTitle, setScenarioTitle] = useState('MENU');

  
  const handleButtonClick = (button: string | null) => {
    switch (button) {

        case 'B2':
          setCurrentStep((currentStep)=> {
            return currentStep-1
          }
          );
          break;
        case 'B4':
            setCurrentStep((currentStep)=> currentStep+1);
          break;
        case 'B7':
          setSelectedScenario?.(Scenarios?.ScenarioTestCombustion)
          setSeed?.(15)
          break;

      default:
        break;
    }
  }; 
  // Call handleButtonClick when the clickedButton prop changes
  React.useEffect(() => {
    console.log(' button clicked');
    handleButtonClick(clickedButton);
  }, [clickedButton]);

  useEffect(() => {
    switch (currentStep) {
      case 1:
        updateScrollBar(0)
        handleActiveButtons(['B4', 'B7']);
        handleAlertMessage(true,"Le scénario n’est pas encore disponible");
        break;
      case 2:
        updateScrollBar(0)
        handleActiveButtons([ 'B2', 'B4', 'B7']);
        break;
      case 3:
        updateScrollBar(0)
        handleActiveButtons([ 'B2', 'B4', 'B7']);
        break;
      case 4:
        updateScrollBar(100)
        handleActiveButtons([ 'B2', 'B7']);
        break;
      default:
        break;
    }
  },[currentStep]);

 
  const updateScrollBar = (scrollPosition :number) => {
    if (scrollableContainerRef.current) {
      const containerHeight = scrollableContainerRef.current.scrollHeight - scrollableContainerRef.current.clientHeight;
      const scrollY = (scrollPosition / 100) * containerHeight;
      scrollableContainerRef.current.scrollTo(0, scrollY);
    }
  };
  function handleActiveButtons(buttons: string[]) {
    changeActiveButtons(buttons);
  }


  const renderStep = () => {
    switch (currentStep) {
      case 1:
        updateScrollBar(0)
        return <MenuList currentStep={currentStep} />;
      case 2:
        updateScrollBar(0)
        return <MenuList currentStep={currentStep} />;
      case 3:
        updateScrollBar(0)
        return <MenuList currentStep={currentStep} />;
      case 4:
        updateScrollBar(100)
        return <MenuList currentStep={currentStep} />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className=" text-white ">
        <h1 className="text-center text-[10px]">{scenarioTitle}</h1>
        <hr className="border-[#686c73] border-1 mx-1" /> 
        <div className={` h-[9.5rem] mb-2 w-full overflow-y-auto ${styles['scrollable-container']}`}  ref={scrollableContainerRef}>
            {renderStep()}
        </div>
      </div>
    </>
  );
};

export default ScenarioMenu;


