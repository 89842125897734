import React, { useState, useEffect } from "react";

const BarAnimation: React.FC<{
  minute: number;
  second: number;
  animationStateMessage: string;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}> = ({ minute, second, animationStateMessage, setCurrentStep }) => {
  const totalDuration = minute * 60 + second;
  const [progress, setProgress] = useState(0);
  const [timeCounter, setTimeCounter] = useState(totalDuration);

  useEffect(() => {
    let interval: ReturnType<typeof setTimeout>;

    if (timeCounter > 0) {
      interval = setInterval(() => {
        setProgress((prevProgress) => (prevProgress < 100 ? prevProgress + 100 / totalDuration*60 : 100));
        setTimeCounter((prevTime) => (prevTime > 0 ? prevTime - 60 : 0));
      }, 1000);
    } else {
      setCurrentStep((prevStep) => prevStep + 1);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timeCounter, totalDuration, setCurrentStep]);

  const displayMinutes = Math.floor(timeCounter / 60);
  const displaySeconds = timeCounter % 60;

  return (
    <div className=" ">
      <div className="bg-[#222526] h-[8.5rem]  m-2 flex">
        <div className="text-[11px] p-2 text-[#C3CBD9]">
          <div className="w-[13rem] m-3 my-2 bg-[#686C73] h-4">
            <div className="bg-[#C3CBD9] h-4" style={{ width: `${progress}%` }} />
          </div>
          <div>{displayMinutes < 10 ? `0${displayMinutes}` : displayMinutes}:{displaySeconds < 10 ? `0${displaySeconds}` : displaySeconds}</div>
          {animationStateMessage}
        </div>
      </div>
    </div>
  );
};

export default BarAnimation;
