import { LuChevronsUpDown } from "react-icons/lu";

const UpDownSelection: React.FC<{ title: string; selection: string }> = ({ title, selection }) => (
  <div className="bg-[#222526] mx-1 mt-8">
    <p className="text-left text-[10px] p-2 text-[#C3CBD9]">{title}</p>
    <div className="text-[12px]  mb-8">
      <LuChevronsUpDown className="inline m-1 text-lg text-[#c3cbd9]" />
      <div className="bg-[#c3cbd9] text-black cursor-pointer text-right inline-block mb-2 w-48 py-2 pr-5">
        {selection}
      </div>
    </div>
  </div>
);

export default UpDownSelection;
