import React from 'react';
import { LuChevronsUpDown } from 'react-icons/lu';

type TimeProps = {
  isActive: boolean;
  label: string;
  selection: string;
};

const Time: React.FC<TimeProps> = ({ isActive, label, selection }) => (
  <div className={`${isActive ? 'bg-[#c3cbd9] text-black' : 'bg-[#686C73] text-[#C3CBD9]'} text-[11px] inline py-2`}>
    <div className='inline-block w-12 font-exo2'>
      {isActive && <LuChevronsUpDown className='inline text-base' />}
      {selection}
    </div>
    {/* {showColon && <p className='mx-1 text-[#C3CBD9]'>:</p>} */}
  </div>
);

export default Time;
