
const EasyStartPACFinished= ({ easyStartFinishedButton }: { easyStartFinishedButton: string }) => {

    return (
      <div className=" ">
          <div className="bg-[#3a3c40] h-[16.5rem] w-[98.5%]  m-2 ">
              <div className="text-[14px] p-2 text-[#fff]">
                  <div className="my-4 ">Félicitations ! <br/> L'EasyStart est terminé</div>
                  <div className="my-8 ">Pour enregistrer, le système <br/> va redémarrer</div>
                  
                  <div className="border-2  border-[#e2ebff]">
                    <div className={`${easyStartFinishedButton ==='verify'? ' bg-[#e2ebff]  text-[#3a3c40]':' bg-[#3a3c40] text-[#e2ebff]'}   text-[11px] m-1 `}>
                        <div className=' inline-block w-30 py-1'>Vérifier</div>
                    </div>
                  </div>
                  <div className="border-2  border-[#e2ebff] mt-2">
                    <div className={`${easyStartFinishedButton ==='register'? ' bg-[#e2ebff]  text-[#3a3c40]':' bg-[#3a3c40] text-[#e2ebff]'} text-[11px] m-1 `}>
                        <div className=' inline-block w-30 py-1'>Enregistrer</div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
    );
  };
  
  export default EasyStartPACFinished;
  