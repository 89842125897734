import MenuColumn from "./MenuColumn";
import {  FaFlag } from 'react-icons/fa';

type MenuListProps = {
    currentStep: number;
}

const MenuList: React.FC<MenuListProps> =({ 
    currentStep,


}) =>{


    const FIRST_STATE = 1;
    const SECOND_STATE = 2;
    return(
    <>
        <div className=" mx-1 mt-7 mb-2">
            <MenuColumn
                title="Services actifs"
                state={currentStep === 1? FIRST_STATE :SECOND_STATE }
            />
            <MenuColumn
                title="Eau Chaude Sanitaire"
                state={currentStep === 2? FIRST_STATE :SECOND_STATE }
            />
            <MenuColumn
                title="Consommations de gaz"
                state={currentStep === 3? FIRST_STATE :SECOND_STATE }
            />
            <MenuColumn
                title="Paramètres" 
                icon={<FaFlag className= "inline-block" />}
                state={currentStep === 4? FIRST_STATE :SECOND_STATE }
            />
        </div>
    </>)
    };
  
export default MenuList;