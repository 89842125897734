import { AiOutlineCheck } from 'react-icons/ai';
const ValidationScreen: React.FC = () => {
    return (
      <div  className="  ">
      <div className="bg-[#C3CBD9] h-[8rem] w-[15rem]  m-3 flex justify-center items-center">
        <div className="bg-white w-16 h-16 flex justify-center items-center rounded-full">
          <AiOutlineCheck className="inline m-2 text-black text-5xl" />
        </div>
      </div>
      </div>
    );
  };
  
  export default ValidationScreen;
  