import WelcomeMessagePAC from "./WelcomeMessagePAC";


const LanguageSelectionPAC: React.FC<{welcomeMessage:boolean, language:string}> = ({welcomeMessage,language})=>{
    return (
        <>
        <div className={` ${welcomeMessage === true ? "absolute" : "hidden"}`}>
            <div>
            <WelcomeMessagePAC />
            </div>
        </div>
        <div className=" mx-1 mb-2">
            <div className={`${(language === 'Eesti' ) ? ' bg-[#C3CBD9]' : 'bg-[#45474D]'} flex justify-between items-center  mt-1`}>   
                <div className={`${(language === 'Eesti' ) ? ' text-[#222526]' : 'text-[#C3CBD9]'} text-[14px] px-3 py-2 w-full text-center `}>
                Eesti
                </div>
            </div>
            <div className={`${(language === 'English' ) ? ' bg-[#C3CBD9]' : 'bg-[#45474D]'} flex justify-between items-center  mt-1`}>   
                <div className={`${(language === 'English' ) ? ' text-[#222526]' : 'text-[#C3CBD9]'} text-[14px] px-3 py-2 w-full text-center `}>
                English
                </div>
            </div>
            <div className={`${(language === 'Español' ) ? ' bg-[#C3CBD9]' : 'bg-[#45474D]'} flex justify-between items-center  mt-1`}>   
                <div className={`${(language === 'Español' ) ? ' text-[#222526]' : 'text-[#C3CBD9]'} text-[14px] px-3 py-2 w-full text-center `}>
                Español
                </div>
            </div>
            <div className={`${(language === 'Ελληνικά' ) ? ' bg-[#C3CBD9]' : 'bg-[#45474D]'} flex justify-between items-center  mt-1`}>   
                <div className={`${(language === 'Ελληνικά' ) ? ' text-[#222526]' : 'text-[#C3CBD9]'} text-[14px] px-3 py-2 w-full text-center `}>
                Ελληνικά
                </div>
            </div>
            <div className={`${(language === 'Français' ) ? ' bg-[#C3CBD9]' : 'bg-[#45474D]'} flex justify-between items-center  mt-1`}>   
                <div className={`${(language === 'Français' ) ? ' text-[#222526]' : 'text-[#C3CBD9]'} text-[14px] px-3 py-2 w-full text-center `}>
                Français
                </div>
            </div>
            <div className={`${(language === 'Italiano' ) ? ' bg-[#C3CBD9]' : 'bg-[#45474D]'} flex justify-between items-center  mt-1`}>   
                <div className={`${(language === 'Italiano' ) ? ' text-[#222526]' : 'text-[#C3CBD9]'} text-[14px] px-3 py-2 w-full text-center `}>
                Italiano
                </div>
            </div>
            <div className={`${(language === 'Lietuvių k.' ) ? ' bg-[#C3CBD9]' : 'bg-[#45474D]'} flex justify-between items-center  mt-1`}>   
                <div className={`${(language === 'Lietuvių k.' ) ? ' text-[#222526]' : 'text-[#C3CBD9]'} text-[14px] px-3 py-2 w-full text-center `}>
                Lietuvių k.
                </div>
            </div>
            <div className={`${(language === 'Nederlands' ) ? ' bg-[#C3CBD9]' : 'bg-[#45474D]'} flex justify-between items-center  mt-1`}>   
                <div className={`${(language === 'Nederlands' ) ? ' text-[#222526]' : 'text-[#C3CBD9]'} text-[14px] px-3 py-2 w-full text-center `}>
                Nederlands
                </div>
            </div>

        </div>
        </>
    )
}

export default LanguageSelectionPAC;