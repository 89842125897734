import { AiOutlineCheck } from 'react-icons/ai';
const WaterPopUp: React.FC = () => {
    return (
      <div  className=" relative z-10 mt-3 ">
      <div className="bg-[#3a3c40] h-[16.5rem] w-[200px] flex flex-col   items-center">
        <div className=" my-4">
            <div className='flex justify-center align-center'>                
              <img className=' w-3' src={`images/robinet-active.svg`} alt='' /> <span> - Eau chaude </span>
            </div> 
            <img className=' mx-auto my-4 w-28' src={`images/Goutte-red.svg`} alt='' /> 
            <div className="border-2 w-40 border-[#e2ebff]">
                <div className={` bg-[#e2ebff]  text-[#3a3c40] m-[2px]   text-[11px] `}>
                    <div className=' inline-block  py-1'>Lancer un Boost ?</div>
                </div>
            </div>
        </div>
      </div>
      </div>
    );
  };
  
  export default WaterPopUp;
  