import React from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { LuChevronsUpDown } from 'react-icons/lu';

type TimeElementProps = {
  isActive: boolean;
  selection: string;
  customClass: string;
  customWidth: string;
};

const TimeElement: React.FC<TimeElementProps> = ({ 
    isActive, 
    selection, 
    customClass,
    customWidth,}) => (
  <div >
    <div className={` inline-block font-exo2 mx-2 flex flex-col items-center`}>
      {isActive && <FaAngleUp />}
      <div className={`${isActive ? 'bg-[#c3cbd9] text-black' : 'underline text-[#C3CBD9] '} text-[11px] inline p-1`}>
        {selection}
      </div>
      {isActive && <FaAngleDown />}


    </div>
  </div>
);

export default TimeElement;
