import React from 'react';
import ColumnSelection from './ColumnSelection';
import ValidationScreen from '../../../../../../shared/components/ValidationScreen';

type TestCombustionListProps = {
  burnerModulation: string;
  valvePosition: string;
  startTemperature: string;
  returnTemperature: string;
  ionisationCurrent: string;
  ventilatorSpeed: string;
  currentStep: number;
  
};

const TestCombustionList: React.FC<TestCombustionListProps> = ({
  burnerModulation,
  valvePosition,
  startTemperature,
  returnTemperature,
  ionisationCurrent,
  ventilatorSpeed,
  currentStep
}) => {

    const FIRST_STATE = 1;
    const SECOND_STATE = 2;
    const THIRD_STATE = 3;
    return(
        <>
            <div className={` ${currentStep === 14 ? "absolute" : "hidden"}`}>
                <div>
                <ValidationScreen />
                </div>
            </div>
            <div className='mt-5'>
            
            <ColumnSelection
                title="Modulation brûleur"
                value={burnerModulation}
                state={currentStep === 1? FIRST_STATE :currentStep === 2? SECOND_STATE:THIRD_STATE}
            />
            
            <ColumnSelection
                title="Position vanne 3 voies"
                value={valvePosition}
                state={currentStep === 3? FIRST_STATE :currentStep === 4? SECOND_STATE:THIRD_STATE}
            />
            <ColumnSelection
                title="Température départ chaudière"
                value={startTemperature}
                state={currentStep === 5? FIRST_STATE :currentStep === 6? SECOND_STATE:THIRD_STATE}
            />

            <ColumnSelection
                title="Température retour chaudière"
                value={returnTemperature}
                state={currentStep === 7? FIRST_STATE :currentStep === 8? SECOND_STATE:THIRD_STATE}
            />

            <ColumnSelection
                title="Courant d'ionisation"
                value={ionisationCurrent}
                state={currentStep === 9? FIRST_STATE :currentStep === 10? SECOND_STATE:THIRD_STATE}
            />
            <ColumnSelection
                title="Vitesse ventilateur"
                value={ventilatorSpeed}
                state={currentStep === 11? FIRST_STATE :currentStep === 12? SECOND_STATE:THIRD_STATE}
            />
            
            <div className='bg-[#C3CBD9] text-[#222526] text-[11px] inline py-2 '>
                <div className=' inline-block w-32 my-6'>Terminer</div>
            </div>
                    
        </div>
        </>
        )
};

export default TestCombustionList;
