
const WelcomeMessagePAC= () => {

  return (
    <div className=" ">
        <div className="bg-[#222526] h-[16.5rem] w-[98.5%]  m-2 ">
            <div className="text-[14px] p-2 text-[#C3CBD9]">
                <div>Bienvenue dans l'EasyStart !</div>
                <div className="my-10 ">Plus que quelques <br/> étapes pour configurer<br/> le système</div>
                <div className='bg-[#C3CBD9] text-[#222526] text-[11px] inline py-2 '>
                    <div className=' inline-block w-32 my-6'>Continuer</div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default WelcomeMessagePAC;
