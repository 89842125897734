
interface ProgressBarProps {
    progress: number;
  }
  
  const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => (
    <div className="bg-[#686c73] h-1 w-full">
      <div className="bg-[#c3cbd9] h-full" style={{ width: `${progress}%` }}></div>
    </div>
  );
export default ProgressBar;