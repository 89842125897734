import { useEffect, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai"




const ReportTestCombustion: React.FC =(()=>{
    const [pressureThresholdSelection, setPressureThresholdSelection] = useState(1.5);

    // Load the value from local storage on component mount
    useEffect(() => {
      const storedValue = localStorage.getItem('EASYSTART-pressure');
      if (storedValue !== null) {
        setPressureThresholdSelection(parseFloat(storedValue));
      }
    }, []);
  
    return(
        <div className=" h-[11rem] flex flex-col bg-black text-white p-1">
            <div  className="bg-[#222526] h-1/2  mb-1 rounded flex items-center ">
                <div className=" text-xs font-extralight w-4/5">
                    <AiOutlineCheck className="inline rounded  bg-[#63AE65] p-1 w-10 h-10 mr-6" />
                    Fonctionnement OK
                </div>
            </div>
            <div  className="bg-[#222526] h-1/2 rounded flex items-center">
                <div className=" text-xs font-extralight w-5/6">
                    <img
                    className=' w-10 inline mr-2'
                    src="images/pressure.svg"
                    alt=''
                    /> 
                    <span className="text-xl">{pressureThresholdSelection}</span> bar
                
                    <img
                        className=' ml-16 text-left w-4 inline'
                        src="images/fire.svg"
                        alt=''
                    /> 
                </div>
            </div>
        </div>
    )

})

export default ReportTestCombustion