import { AiOutlineCheck } from 'react-icons/ai';
import { FiCheckCircle } from 'react-icons/fi';
const PressurePopUp: React.FC = () => {
    return (
      <div  className="  relative z-10 mt-3 ">
      <div className="bg-[#3a3c40] h-[16.5rem] w-[200px]   m-2 flex flex-col  items-center">
        <div className=" my-4">
            <div > Pression hydrautique </div> 
            <img className=' mx-auto my-6 w-28' src={`images/Pressure-gray-with-values.png`} alt='' /> 
            <div className="border-2 w-40 border-[#e2ebff]">
                <div className={` bg-[#e2ebff]  text-[#3a3c40] m-[2px]   text-[11px] `}>
                    <div className=' inline-block  py-1'>Fermer</div>
                </div>
            </div>
        </div>
      </div>
      </div>
    );
  };
  
  export default PressurePopUp;
  