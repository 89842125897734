import { BsExclamationTriangleFill } from "react-icons/bs";

const WarningAirPurgeInterompre= () => {

    return (
      <div className=" relative z-10 flex flex-col items-center ">
          <div className="bg-[#3a3c40] h-[16.5rem] w-[190px]  m-2 ">
              <div className="text-[12px] p-2 text-[#fff] flex flex-col items-center">
                  <div className="">Cycle Dégazage <br/> pendant 258 secondes</div>
                  <div className=" text-xs font-extralight">
                        <BsExclamationTriangleFill className="inline rounded text-[#fab237] p-1 w-16 h-16 " />
                    </div>
                  <div className=" p-2">Pour chasser l'air résiduel <br/> vers les purgeurs en<br/>quelques minutes</div>
                  
                  <div className="border-2 w-40 border-[#e2ebff]">
                    <div className={` bg-[#e2ebff]  text-[#3a3c40] m-[2px]   text-[11px] `}>
                        <div className=' inline-block  py-1'>Continuer</div>
                    </div>
                  </div>
                  <div className="border-2 w-40 border-[#e2ebff] mt-2">
                    <div className={` bg-[#3a3c40] text-[#e2ebff] text-[11px] `}>
                        <div className=' inline-block  py-1'>interrompre</div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
    );
  };
  
  export default WarningAirPurgeInterompre;
  