import React, { useEffect, useRef, useState } from 'react';
import { Scenarios, ScreenScenarioProps } from '../../../../../shared/types';
import styles from '../../../../../styles/ScenarioEasyStart.module.css';
import ProgressBar from '../../../../../shared/components/ProgressBar';
import LanguageSelection from './components/LanguageSelection';
import TimeSelection from './components/TimeSelection';
import ValidationScreen from '../../../../../shared/components/ValidationScreen';
import PressureThreshold from './components/PressureThreshold';
import ReportEasyStart from './components/ReportEasyStart';
import CompleteInstalation from './components/CompleteInstalation';
import BarAnimation from './components/BarAnimation';
import UpDownSelection from './components/UpDownSelection';
import BarAnimationFinished from './components/BarAnimationFinished';


type ScrollableContainerRef = React.RefObject<HTMLDivElement>;

const ScenarioEasyStart: React.FC<ScreenScenarioProps> = ({ 
  clickedButton, 
  changeActiveButtons, 
  handleAlertMessage ,
  setSelectedScenario}) => {
    
  const [currentStep, setCurrentStep] = useState(1);


  const [showWelcomeMessage,setShowWelcomeMessage] = useState(true);
  const [progressBar, setProgressBar] = useState(0);
  const [language, setLanguage] = useState('Français');
  const [scenarioTitle, setScenarioTitle] = useState('Easy Start');
  const [daySelection, setDaySelection] = useState('01');
  const [monthSelection, setMonthSelection] = useState('Janvier');
  const [yearSelection, setYearSelection] = useState('2023');
  const [hourSelection, setHourSelection] = useState('09');
  const [minuteSelection, setMinuteSelection] = useState('00');
  const [kityesNoOption, setKityesNoOption] = useState('Non');
  const [transmitterTypeSelection, setTransmitterTypeSelection] = useState('Radiateurs');
  const [pressureThresholdSelection, setPressureThresholdSelection] = useState(1.5);
  const scrollableContainerRef: ScrollableContainerRef = useRef<HTMLDivElement>(null);
  const [putInServiceSelection, setPutInServiceSelection] = useState('Oui');
  const [triggerAirPurgeSelection, setTriggerAirPurgeSelection] = useState('Oui');
  const [startCalibrationSelection, setStartCalibrationSelection] = useState('Chauffage');

  const languages = ['Français', 'English']; 
  const daySelections = ['01', '02','03']; 
  const monthSelections = ['Janvier', 'Février','Mars']; 
  const yearSelections = ['2023', '2024','2025']; 
  const hourSelections = ['09', '10','11'];
  const minuteSelections = ['00','01', '02'];
  const yesNoOptions = ['Oui', 'Non']; 
  const transmitterTypeSelections = ['Radiateurs', 'Plancher chauffant']; 
  const startCalibrationSelections = ['Chauffage','Sanitaire']; 


  const stepValidationDelayMap : {[step: number]:  { nextStep: number; delay: number }} = {
    13: { nextStep: 14, delay: 2000 },
    15: { nextStep: 16, delay: 3000 },
  };

    // store pressureThresholdSelection on local storage whenever the value changes
    useEffect(() => {
      localStorage.setItem('EASYSTART-pressure', pressureThresholdSelection.toString());
    }, [pressureThresholdSelection]);
  
  // ***************************** WelcomeMessage ***********************
  useEffect(()=>{
    const timer = setTimeout(()=>{
      setShowWelcomeMessage(false);
    },4000);
    return() =>clearTimeout(timer);
  },[]);


  // Call handleButtonClick when the clickedButton prop changes
  React.useEffect(() => {
    handleButtonClick(clickedButton);
  }, [clickedButton]);

  useEffect(() => {
// ***************************** ScenarioTitle *********************
    updateScenarioTitle();
// ***************************** ProgressBar ***********************
    updateProgressBar();
// ***************************** Handle Validation TIME ************
    validateStepWithDelay(currentStep);
  }, [currentStep]);


  useEffect(() => {

    switch (currentStep) {
      case 1:
        changeActiveButtons(['B2', 'B4', 'B6']);
        handleAlertMessage(true,"D’autres langues sont aussi disponibles.");
        break;
      case 2:
        changeActiveButtons(['B2', 'B4', 'B5','B6']);
        handleAlertMessage(true,"Sur le produit fini, à cette étape il est possible de paramétrer le passage à l’heure d’été ou d’hiver de façon manuelle ou automatique. Par ailleurs, en cas de coupure de courant, la chaudière garde en mémoire les paramètres de date et heure pendant plusieurs heures.");
        break;
      case 3:
        changeActiveButtons(['B2', 'B4', 'B5','B6']);
        handleAlertMessage(true,"Sur le produit fini, à cette étape il est possible de paramétrer le passage à l’heure d’été ou d’hiver de façon manuelle ou automatique. Par ailleurs, en cas de coupure de courant, la chaudière garde en mémoire les paramètres de date et heure pendant plusieurs heures.");
        break;
      case 4:
        changeActiveButtons(['B2', 'B4', 'B5','B6']);
        handleAlertMessage(true,"Sur le produit fini, à cette étape il est possible de paramétrer le passage à l’heure d’été ou d’hiver de façon manuelle ou automatique. Par ailleurs, en cas de coupure de courant, la chaudière garde en mémoire les paramètres de date et heure pendant plusieurs heures.");
        break;       
      case 5:
        changeActiveButtons(['B2', 'B4', 'B5','B6']);
        handleAlertMessage(true,"Sur le produit fini, à cette étape il est possible de paramétrer le passage à l’heure d’été ou d’hiver de façon manuelle ou automatique. Par ailleurs, en cas de coupure de courant, la chaudière garde en mémoire les paramètres de date et heure pendant plusieurs heures.");
        break;
      case 6:
        changeActiveButtons(['B2', 'B4', 'B5','B6']);
        handleAlertMessage(true,"Sur le produit fini, à cette étape il est possible de paramétrer le passage à l’heure d’été ou d’hiver de façon manuelle ou automatique. Par ailleurs, en cas de coupure de courant, la chaudière garde en mémoire les paramètres de date et heure pendant plusieurs heures.");
        break;
      case 7:
        changeActiveButtons(['B2', 'B4', 'B5', 'B6']);
        handleAlertMessage(false);
        break;
      case 8:
        changeActiveButtons(['B2', 'B4', 'B5', 'B6']);
        handleAlertMessage(true,"Vous pouvez définir plancher chauffant ou radiateurs selon la configuration de vos circuits.");
        break;
      case 9:
        changeActiveButtons(['B2', 'B4', 'B5', 'B6']);
        handleAlertMessage(true, "Si vous possédez un disconnecteur à remplissage automatique, celui-ci gère automatiquement le remplissage de la chaudière et vous alerte au cas où les remplissages seraient trop fréquents ou trop longs.");
        break;
      case 10:
        changeActiveButtons(['B2', 'B4', 'B5']);
        handleAlertMessage(true, "Il s’agit d’un récapitulatif des paramètres sélectionnés.");
        break;
      case 11:
        changeActiveButtons(['B2', 'B4', 'B5']);
        handleAlertMessage(true, "Il s’agit d’un récapitulatif des paramètres sélectionnés.");
        break;
      case 12:
        changeActiveButtons(['B2', 'B4', 'B5', 'B6']);
        handleAlertMessage(true, "Il s’agit d’un récapitulatif des paramètres sélectionnés.");
        break;
      case 13:
        changeActiveButtons([]);
        handleAlertMessage(true, "Il s’agit d’un récapitulatif des paramètres sélectionnés.");
        break;
      case 14:
        changeActiveButtons(['B2', 'B4', 'B5', 'B6']);
        handleAlertMessage(false);
        break;
      case 15:
        changeActiveButtons([]);
        handleAlertMessage(true, "A cette étape, vous devez faire un remplissage manuel.");
        break;
      case 16:
        changeActiveButtons(['B5', 'B6']);
        handleAlertMessage(true, "A cette étape, vous devez faire un remplissage manuel.");
        break;
      case 17:
        changeActiveButtons(['B2', 'B4', 'B5', 'B6']);
        handleAlertMessage(false);
        break;
      case 18:
        changeActiveButtons([]);
        handleAlertMessage(true, "Le délai de la purge d’air est accéléré pour la démonstration.");
        break;
      case 19:
        changeActiveButtons(['B5', 'B6']);
        handleAlertMessage(true, "Le délai de la purge d’air est accéléré pour la démonstration.");
        break;
      case 20:
        changeActiveButtons(['B2', 'B4', 'B5', 'B6']);
        handleAlertMessage(false);
        break;
      case 21:
        changeActiveButtons([]);
        handleAlertMessage(true, "Le délai de la calibration gaz est accéléré pour la démonstration.");
        break;
      case 22:
        changeActiveButtons(['B5', 'B6']);
        handleAlertMessage(true, "Le délai de la calibration gaz est accéléré pour la démonstration.");
        break;
      default:
        break;
    }
      }, [currentStep]);

  const validateStepWithDelay = (step: number) => {
    const stepData = stepValidationDelayMap[step];
    if (stepData && stepData.nextStep !== undefined && stepData.delay !== undefined) {
      const { nextStep, delay } = stepData;
      const timeout = setTimeout(() => {
        setCurrentStep(nextStep);
      }, delay);
      return () => clearTimeout(timeout);
    }
  };
  const updateScrollBar = (scrollPosition :number) => {
    if (scrollableContainerRef.current) {
      const containerHeight = scrollableContainerRef.current.scrollHeight - scrollableContainerRef.current.clientHeight;
      const scrollY = (scrollPosition / 100) * containerHeight;
      scrollableContainerRef.current.scrollTo(0, scrollY);
    }
  };
  const updateProgressBar = () => {
    switch (currentStep) {
      case 1:
        setProgressBar(7);
        break;
      case 2 :
        setProgressBar(12);
        break;
      case 7:
        setProgressBar(25);
        break;
      case 8:
        setProgressBar(35);
        break;
      case 9:
        setProgressBar(50);
        break;      
      case 10:
      case 11:
      case 12:
      case 13:
        setProgressBar(70);
        break;
      case 14:
        setProgressBar(75);
        break;
      case 17:
        setProgressBar(80);
        break;
      case 18:
        setProgressBar(85);
        break;
      case 21:
        setProgressBar(100);
        break;
      default:
        break;
    }
  };

  const updateScenarioTitle = () => {
    switch (currentStep) {    
      case 10:
      case 11:
      case 12:
      case 13:
        setScenarioTitle("Bilan Easy Start");
        break;
      case 14:
        setScenarioTitle("Easy Start");
        break;
      default:
        setScenarioTitle("Easy Start");
        break;
    }
  };

  const handleButtonClick = (button: string | null) => {
    console.log("handleButtonClick", button);
    
    switch (button) {
      case 'B2':
        if (currentStep === 1) {
          const currentIndex = languages.indexOf(language);
          const nextIndex = (currentIndex + 1) % languages.length;
          setLanguage(languages[nextIndex]);
        } else if (currentStep === 2 ) {
          const currentIndex = daySelections.indexOf(daySelection);
          const nextIndex = (currentIndex + 1) % daySelections.length;
          setDaySelection(daySelections[nextIndex]);
        } else if (currentStep === 3 ) {
          const currentIndex = monthSelections.indexOf(monthSelection);
          const nextIndex = (currentIndex + 1) % monthSelections.length;
          setMonthSelection(monthSelections[nextIndex]);
        } else if (currentStep === 4 ) {
          const currentIndex = yearSelections.indexOf(yearSelection);
          const nextIndex = (currentIndex + 1) % yearSelections.length;
          setYearSelection(yearSelections[nextIndex]);
        } else if (currentStep === 5 ) {
          const currentIndex = hourSelections.indexOf(hourSelection);
          const nextIndex = (currentIndex + 1) % hourSelections.length;
          setHourSelection(hourSelections[nextIndex]);
        } else if (currentStep === 6 ) {
          const currentIndex = minuteSelections.indexOf(minuteSelection);
          const nextIndex = (currentIndex + 1) % minuteSelections.length;
          setMinuteSelection(minuteSelections[nextIndex]);
        } else if (currentStep === 8 ) {
          const currentIndex = transmitterTypeSelections.indexOf(transmitterTypeSelection);
          const nextIndex = (currentIndex + 1) % transmitterTypeSelections.length;
          setTransmitterTypeSelection(transmitterTypeSelections[nextIndex]);
        }else if (currentStep === 9 ) {
            setPressureThresholdSelection((pressureThresholdSelection)=>{ 
            return parseFloat((pressureThresholdSelection + 0.1).toFixed(1))
          });
        } else if (currentStep === 11 || currentStep === 12 ) {
          setCurrentStep((currentStep)=> currentStep-1);
        } else if (currentStep === 14 ) {
          const currentIndex = yesNoOptions.indexOf(putInServiceSelection);
          const nextIndex = (currentIndex + 1) % yesNoOptions.length;
          setPutInServiceSelection(yesNoOptions[nextIndex]);
        }else if (currentStep === 17 ) {
          const currentIndex = yesNoOptions.indexOf(triggerAirPurgeSelection);
          const nextIndex = (currentIndex + 1) % yesNoOptions.length;
          setTriggerAirPurgeSelection(yesNoOptions[nextIndex]);
        }else if (currentStep === 20 ) {
          const currentIndex = startCalibrationSelections.indexOf(startCalibrationSelection);
          const nextIndex = (currentIndex + 1) % startCalibrationSelections.length;
          setStartCalibrationSelection(startCalibrationSelections[nextIndex]);
        }
        break;
      case 'B4':
        if (currentStep === 1) {
          const currentIndex = languages.indexOf(language);
          const prevIndex = (currentIndex - 1 + languages.length) % languages.length;
          setLanguage(languages[prevIndex]);
        } else if (currentStep === 2 ) {
          const currentIndex = daySelections.indexOf(daySelection);
          const prevIndex = (currentIndex - 1 + daySelections.length) % daySelections.length;
          setDaySelection(daySelections[prevIndex]);
        } else if (currentStep === 3 ) {
          const currentIndex = monthSelections.indexOf(monthSelection);
          const prevIndex = (currentIndex - 1 + monthSelections.length) % monthSelections.length;
          setMonthSelection(monthSelections[prevIndex]);
        } else if (currentStep === 4 ) {
          const currentIndex = yearSelections.indexOf(yearSelection);
          const prevIndex = (currentIndex - 1 + yearSelections.length) % yearSelections.length;
          setYearSelection(yearSelections[prevIndex]);
        } else if (currentStep === 5 ) {
          const currentIndex = hourSelections.indexOf(hourSelection);
          const prevIndex = (currentIndex - 1 + hourSelections.length) % hourSelections.length;
          setHourSelection(hourSelections[prevIndex]);
        } else if (currentStep === 6 ) {
          const currentIndex = minuteSelections.indexOf(minuteSelection);
          const prevIndex = (currentIndex - 1 + minuteSelections.length) % minuteSelections.length;
          setMinuteSelection(minuteSelections[prevIndex]);
        } else if (currentStep === 8 ) {
          const currentIndex = transmitterTypeSelections.indexOf(transmitterTypeSelection);
          const prevIndex = (currentIndex - 1 + transmitterTypeSelections.length) % transmitterTypeSelections.length;
          setTransmitterTypeSelection(transmitterTypeSelections[prevIndex]);
        }else if (currentStep === 9 ) {
          setPressureThresholdSelection((pressureThresholdSelection)=>{ 
            return parseFloat((pressureThresholdSelection - 0.1).toFixed(1))
          });
        } else if (currentStep === 10 || currentStep === 11) {
          setCurrentStep((currentStep)=> currentStep+1);
        } else if (currentStep === 14 ) {
          const currentIndex = yesNoOptions.indexOf(putInServiceSelection);
          const prevIndex = (currentIndex - 1 + yesNoOptions.length) % yesNoOptions.length;
          setPutInServiceSelection(yesNoOptions[prevIndex]);
        } else if (currentStep === 17 ) {
          const currentIndex = yesNoOptions.indexOf(triggerAirPurgeSelection);
          const prevIndex = (currentIndex - 1 + yesNoOptions.length) % yesNoOptions.length;
          setTriggerAirPurgeSelection(yesNoOptions[prevIndex]);
        } else if (currentStep === 20 ) {
          const currentIndex = startCalibrationSelections.indexOf(startCalibrationSelection);
          const prevIndex = (currentIndex - 1 + startCalibrationSelections.length) % startCalibrationSelections.length;
          setStartCalibrationSelection(startCalibrationSelections[prevIndex]);
        }
        break;

        case 'B5':
          setCurrentStep((currentStep)=> {
            if (currentStep==17|| currentStep==20 || currentStep==23) {
              return currentStep-3;
            }
            if (currentStep==7 || currentStep==14 || currentStep==16  || currentStep==19 || currentStep==22 ) {
              return currentStep-2;
            }
            return currentStep-1
          }
          );
          break;
        case 'B6':
          if(currentStep ===22){
            setSelectedScenario?.(Scenarios?.ScenarioTestCombustion);
          }
            setCurrentStep((currentStep)=> currentStep+1);
          break;
      default:
        break;
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        console.log("[Easy Start] Render case1")
        updateScrollBar(0)
        return <LanguageSelection language={language} />;
      case 2:
        console.log("[Easy Start] Render case2")
        updateScrollBar(0)
        return <TimeSelection
                currentStep={currentStep}
                daySelection={daySelection}
                monthSelection={monthSelection}
                yearSelection={yearSelection}
                hourSelection={hourSelection}
                minuteSelection={minuteSelection}
              />;
      case 3:
        console.log("[Easy Start] Render case3")

        updateScrollBar(0)
        return <TimeSelection
                currentStep={currentStep}
                daySelection={daySelection}
                monthSelection={monthSelection}
                yearSelection={yearSelection}
                hourSelection={hourSelection}
                minuteSelection={minuteSelection}
              />;
      case 4:
        updateScrollBar(0)
        return <TimeSelection
          currentStep={currentStep}
          daySelection={daySelection}
          monthSelection={monthSelection}
          yearSelection={yearSelection}
          hourSelection={hourSelection}
          minuteSelection={minuteSelection}
        />;        
      case 5:
        updateScrollBar(0)
        return <TimeSelection
              currentStep={currentStep}
              daySelection={daySelection}
              monthSelection={monthSelection}
              yearSelection={yearSelection}
              hourSelection={hourSelection}
              minuteSelection={minuteSelection}
            />;
      case 6:
        updateScrollBar(0)
        return <TimeSelection
              currentStep={currentStep}
              daySelection={daySelection}
              monthSelection={monthSelection}
              yearSelection={yearSelection}
              hourSelection={hourSelection}
              minuteSelection={minuteSelection}
            />;
      case 7:
        updateScrollBar(0)
        return <UpDownSelection title="Présence d’un kit 2 circuits" selection={kityesNoOption} />;
      case 8:
        updateScrollBar(0)
        return <UpDownSelection title="Type d'émetteurs circuit 1" selection={transmitterTypeSelection} />;
      case 9:
        updateScrollBar(0)
        return <PressureThreshold selection={pressureThresholdSelection} />;
      case 10:
        updateScrollBar(0)
        return <ReportEasyStart currentStep={currentStep} kityesNoOption={kityesNoOption} transmitterTypeSelection={transmitterTypeSelection} pressureThresholdSelection={pressureThresholdSelection}  />;
      case 11:
        updateScrollBar(100)
        return <ReportEasyStart currentStep={currentStep} kityesNoOption={kityesNoOption} transmitterTypeSelection={transmitterTypeSelection} pressureThresholdSelection={pressureThresholdSelection}  />;
      case 12:
        updateScrollBar(100)
        return <ReportEasyStart currentStep={currentStep} kityesNoOption={kityesNoOption} transmitterTypeSelection={transmitterTypeSelection} pressureThresholdSelection={pressureThresholdSelection}  />;
      case 13:
        updateScrollBar(100)
        return <ReportEasyStart currentStep={currentStep} kityesNoOption={kityesNoOption} transmitterTypeSelection={transmitterTypeSelection} pressureThresholdSelection={pressureThresholdSelection}  />;
      case 14:
        updateScrollBar(0)
        return <UpDownSelection title="Souhaitez-vous effectuer la mise en service ?" selection={putInServiceSelection} />;
      case 15:
        updateScrollBar(0)
        return <CompleteInstalation selectedPressure={pressureThresholdSelection} finished={false} />;
      case 16:
        updateScrollBar(0)
        return <CompleteInstalation selectedPressure={pressureThresholdSelection} finished={true}   />;
      case 17:
        updateScrollBar(0)
        return <UpDownSelection title="Déclencher un cycle de purge d’air ?" selection={triggerAirPurgeSelection} />;
      case 18:
        updateScrollBar(0)
        return <BarAnimation minute={4} second={0} animationStateMessage="Purge d’air en cours"  setCurrentStep={setCurrentStep}   />;
      case 19:
        updateScrollBar(0)
        return <BarAnimationFinished animationStateMessage="Purge d’air terminée" />;
      case 20:
        updateScrollBar(0)
        return <UpDownSelection title="Démarrer calibration gaz sur circuit" selection={startCalibrationSelection} />;
      case 21:
        updateScrollBar(0)
        return <BarAnimation minute={2} second={30} animationStateMessage="Démarrage calibration gaz" setCurrentStep={setCurrentStep}   />;
      case 22:
        updateScrollBar(0)
        return <BarAnimationFinished  animationStateMessage="Calibration gaz terminée" />;
      default:
        return null;
    }
  };


  return (
    <div className='h-[10.5rem]'>
    <div className={`${styles['welcome-message']}   h-40 bg-black  ${showWelcomeMessage ? '' :styles['hidden'] }  `}>
      
      <img src="images/welcome-message.svg" alt="" className='h-[10.5rem]' />
    </div>
    
    <div className= {`text-white  ${showWelcomeMessage ? 'hidden':'' }  `}>
      <h1 className="text-center text-[10px]">{scenarioTitle}</h1>
      <hr className="border-[#686c73] border-1" />
      <div className={`h-[9.5rem] w-full overflow-y-auto ${styles['scrollable-container']}`}  ref={scrollableContainerRef}>
          {renderStep()}
      </div>
      <ProgressBar progress={progressBar} />
    </div>
    </div>

  );
};

export default ScenarioEasyStart;
