import { useEffect, useRef, useState } from "react";
import { Scenarios, ScreenScenarioProps } from "../../../../../shared/types"
import { FaFlag } from "react-icons/fa";
import styles from '../../../../../styles/ScenarioEasyStartPAC.module.css';
import LanguageSelectionPAC from "../ScenarioEasyStartPAC/components/LanguageSelectionPAC";
import MenuListPAC from "./components/MenuListPAC";
import { RiAccountBoxFill } from "react-icons/ri";
import MenuPramListPAC from "./components/MenuPramListPAC";
import MenuInstallPAC from "./components/MenuInstallPAC";
import { IoIosSettings } from "react-icons/io";
import { switchToNextElement, switchToPreviousElement } from "../../../../../shared/utils";


type ScrollableContainerRef = React.RefObject<HTMLDivElement>;


const ScenarioMenuPAC: React.FC<ScreenScenarioProps> = ({ 
    clickedButton, 
    changeActiveButtons, 
    handleAlertMessage,
    setSelectedScenario,
    setSeed
  })=>{
    
    const MENU_FIRST_LEVEL_STEP = 1
    const MENU_PARAM_SECOND_LEVEL_STEP = 2
    const MENU_INSTALL_THIRD_LEVEL_STEP = 4


    const [currentStep, setCurrentStep] = useState(1);
    const [scenarioTitle, setScenarioTitle] = useState<JSX.Element>(<></>);
    const [installationModalButton, setInstallationModalButton] = useState('quit');
    const installationModalButtons = ['quit','access']; 

    const [menuFirstLevelElement, setMenuFirstLevelElement] = useState("SA");
    const [menuParamSecondLevelElement, setMenuParamSecondLevelElement] = useState("LANG");
    const [menuInstallThirdLevelElement, setMenuInstallThirdLevelElement] = useState("SA");
    const menuFirstLevelElements = ["SA", "ECS", "AC", "ABS", "SDE", "PAR"];
    const menuParamSecondLevelElements = ["LANG", "DH", "NDC", "WIFI", "ENT", "NOT", "VL", "MI"];
    const menuInstallThirdLevelElements = ["SA", "OI", "ECS", "CF", "PAC", "FA", "RR", "DIAG"];

    const scrollableContainerRef: ScrollableContainerRef = useRef<HTMLDivElement>(null);
    
    const updateScrollBar = (scrollPosition :number) => {
      if (scrollableContainerRef.current) {
        const containerHeight = scrollableContainerRef.current.scrollHeight - scrollableContainerRef.current.clientHeight;
        const scrollY = (scrollPosition / 100) * containerHeight;
        scrollableContainerRef.current.scrollTo(0, scrollY);
      }
    };

    useEffect(() => {

      switch (currentStep) {
        case MENU_FIRST_LEVEL_STEP:
          {

            if(menuFirstLevelElement ==="PAR"){

              changeActiveButtons(['OK','DOWN',"UP","MENU"]);
            }
            else{
              changeActiveButtons(['DOWN',"UP","MENU"]);
            }
            setScenarioTitle(<>
              <h1 className="text-[14px] pl-3 flex-grow text-center">Menu</h1>
            </>);
            handleAlertMessage(true,"Selon votre produit certains menus présents ici peuvent ne pas apparaître. Tous les scénarios ne sont pas disponibles.");
          }
          break;
        case MENU_PARAM_SECOND_LEVEL_STEP:
          {

            if(menuFirstLevelElement ==="PAR" && menuParamSecondLevelElement ==="MI"){

              changeActiveButtons(['OK','DOWN',"UP", "RETURN","MENU"]);
            }
            else{
              changeActiveButtons(['DOWN',"UP", "RETURN","MENU"]);
            }
            setScenarioTitle(<>
            <h1 className="text-[14px] pl-3 flex-grow text-center">Paramètres</h1>
            <div className="m-1"><IoIosSettings /></div>
            </>);
            handleAlertMessage(true,"Selon votre produit certains menus présents ici peuvent ne pas apparaître. Tous les scénarios ne sont pas disponibles.");
          }
          break;
        case MENU_INSTALL_THIRD_LEVEL_STEP:
          {

            changeActiveButtons(['DOWN',"UP", "RETURN", "MENU"]);
            setScenarioTitle(<>
              <h1 className="text-[14px] pl-3 flex-grow text-center">Menu Installateur</h1>
              <div className="m-1"><img
                        className='w-4 inline'
                        src={`images/Installateur.svg`}
                        alt=''
                    /> 
                    </div>
              </>);
            handleAlertMessage(true,"Selon votre produit certains menus présents ici peuvent ne pas apparaître.");
          }
          break;
        default:
          break;
      }
        }, [currentStep, menuFirstLevelElement, menuParamSecondLevelElement]);

  //   **********  Handle buttons click
  const handleButtonClick = (button: string | null) => {
    
    switch (button) {
      case 'OK':
        if (currentStep === MENU_FIRST_LEVEL_STEP && menuFirstLevelElement=="PAR" &&menuParamSecondLevelElement!=='MI') {
          setCurrentStep(MENU_PARAM_SECOND_LEVEL_STEP);
        }
        else if (currentStep === MENU_PARAM_SECOND_LEVEL_STEP+1 && installationModalButton ==="quit" ) {
          setCurrentStep(MENU_PARAM_SECOND_LEVEL_STEP);
        }
        else{
          setCurrentStep((currentStep)=> currentStep+1);
        }        
        break;
      case 'RETURN':
          if (currentStep === MENU_INSTALL_THIRD_LEVEL_STEP) {
          setCurrentStep( MENU_PARAM_SECOND_LEVEL_STEP);
          }
          else{
            setCurrentStep((currentStep)=> currentStep-1);
          }
        break;
        case 'DOWN':
          {
            if (currentStep === MENU_FIRST_LEVEL_STEP) {
              switchToNextElement(menuFirstLevelElements,menuFirstLevelElement,setMenuFirstLevelElement)
            }
            if (currentStep === MENU_PARAM_SECOND_LEVEL_STEP) {
              switchToNextElement(menuParamSecondLevelElements,menuParamSecondLevelElement,setMenuParamSecondLevelElement)
            }
            if (currentStep === MENU_PARAM_SECOND_LEVEL_STEP+1) {
              switchToNextElement(installationModalButtons,installationModalButton,setInstallationModalButton)
            }
            if (currentStep === MENU_INSTALL_THIRD_LEVEL_STEP) {
              switchToNextElement(menuInstallThirdLevelElements,menuInstallThirdLevelElement,setMenuInstallThirdLevelElement)
            }
          }
        break;
        case 'UP':
          {
            if (currentStep === MENU_FIRST_LEVEL_STEP) {
              switchToPreviousElement(menuFirstLevelElements,menuFirstLevelElement,setMenuFirstLevelElement)
            }
            if (currentStep === MENU_PARAM_SECOND_LEVEL_STEP) {
              switchToPreviousElement(menuParamSecondLevelElements,menuParamSecondLevelElement,setMenuParamSecondLevelElement)
            }
            if (currentStep === MENU_PARAM_SECOND_LEVEL_STEP+1) {
              switchToPreviousElement(installationModalButtons,installationModalButton,setInstallationModalButton)
            }
            if (currentStep === MENU_INSTALL_THIRD_LEVEL_STEP) {
              switchToPreviousElement(menuInstallThirdLevelElements,menuInstallThirdLevelElement,setMenuInstallThirdLevelElement)
            }
          }
        break;
        case 'MENU':
          if(setSelectedScenario && setSeed){
            setSeed(5)
            setSelectedScenario(Scenarios.ScenarioAccueilPAC)
          }
        break;
      default:
        break;
    }
  };

    // Call handleButtonClick when the clickedButton prop changes
    useEffect(() => {
        handleButtonClick(clickedButton);
    }, [clickedButton]);

    const renderStep = () => {
        switch (currentStep) {
          case MENU_FIRST_LEVEL_STEP:
            return <MenuListPAC menuElement={menuFirstLevelElement} />;
          case MENU_PARAM_SECOND_LEVEL_STEP:
            if(menuParamSecondLevelElement === 'MI'){
              updateScrollBar(100)
            }else{
              updateScrollBar(0)
            }
            return <MenuPramListPAC menuElement={menuParamSecondLevelElement} />;

          case MENU_PARAM_SECOND_LEVEL_STEP+1:
            if(menuParamSecondLevelElement === 'MI'){
              updateScrollBar(100)
            }else{
              updateScrollBar(0)
            }
            return <MenuPramListPAC menuElement={menuParamSecondLevelElement} selectedButton={installationModalButton} showModal={true}/>;
          case MENU_INSTALL_THIRD_LEVEL_STEP:
            if(menuInstallThirdLevelElement === 'DIAG'){
              updateScrollBar(100)
            }else{
              updateScrollBar(0)
            }
            return <MenuInstallPAC menuElement={menuInstallThirdLevelElement} />;
  
  
        default:
            return null;
        }
    }




        return (<>
        
        <div className="text-white ">

        <div className="flex justify-between items-center">
            {scenarioTitle}
        </div>

            <div className={`h-[17.5rem] w-full overflow-y-auto ${styles['scrollable-container']}`}  ref={scrollableContainerRef}>
                {renderStep()}
            </div>

      </div>
        </>
        )

}

export default  ScenarioMenuPAC;

