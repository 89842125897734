import React from 'react';
import DateSelection from '../../../../RemoteControlNaema/scenarios/ScenarioEasyStart/components/DateSelection';
import Time from '../../../../RemoteControlNaema/scenarios/ScenarioEasyStart/components/Time';
import TimeElement from './TimeElement';

type TimeSelectionProps = {
  label: string;
  daySelection: string;
  monthSelection: string;
  yearSelection: string;
  hourSelection: string;
  minuteSelection: string;
  seasonSelection: string;
};

const TimeSelectionPAC: React.FC<TimeSelectionProps> = ({
  label,
  daySelection,
  monthSelection,
  yearSelection,
  hourSelection,
  minuteSelection,
  seasonSelection,
}) => (
  <>
     <div className={`${(label === 'Day' || label === 'Month' || label === 'Year') ? ' bg-[#5a5e66]' : 'bg-[#17181a]'}  mx-1 mb-1 px-2`}>
      <p className='text-left text-[10px] text-[#C3CBD9] font-lato'>Date</p>
      <div className='flex items-center justify-end'>        
        <TimeElement isActive={label === 'Day'} selection={daySelection} customClass='' customWidth='' />
        <TimeElement isActive={label === 'Month'} selection={monthSelection} customClass='mx-2' customWidth='' />
        <TimeElement isActive={label === 'Year'} selection={yearSelection} customClass='' customWidth='' />
      </div>
    </div>


    <div className={`${(label === 'Hour' || label === 'Minute') ? ' bg-[#5a5e66]' : 'bg-[#17181a]'}  mx-1 mb-1 px-2`}>
      <p className='text-left text-[10px] text-[#C3CBD9] font-lato'>Heure</p>
      <div className='flex items-center justify-end'>
        <TimeElement isActive={label === 'Hour'} selection={hourSelection}  customClass='' customWidth='w-14'/>
          <p className='mx-1 text-[#C3CBD9]'> : </p>
        <TimeElement isActive={label === 'Minute'} selection={minuteSelection}  customClass='' customWidth='w-14' />
      </div>
    </div>


    <div className={`${(label === 'Season') ? ' bg-[#5a5e66]' : 'bg-[#17181a]'}  mx-1 mb-1 px-2`}>
      <p className='text-left text-[10px] text-[#C3CBD9] font-lato'>Eté / Hiver</p>
      <div className='flex items-center justify-end'>
        <TimeElement isActive={label === 'Season'} selection={seasonSelection}  customClass='' customWidth='w-14'/>
      </div>
    </div>
  </>
);

export default TimeSelectionPAC;
