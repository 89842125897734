import { AiOutlineCheck } from "react-icons/ai"
import { BsExclamationTriangleFill } from "react-icons/bs";
import WarningAirPurgeMessage from "./WarningAirPurgeMessage";
import ValidationScreenPAC from "../../ScenarioEasyStartPAC/components/ValidationScreenPAC";
import { useEffect, useState } from "react";
import { WarningAirPurgeMode } from "../../../../../../shared/types";
import WarningAirPurgeInterompre from "./WarningAirPurgeInterompre";


type WarningAirPurgeProps = {
    mode: WarningAirPurgeMode;
    onCountdownTerminate?: () => void; // Optional callback function
    activeButton?: string;
    interrompre?: boolean;

};

const WarningAirPurge: React.FC<WarningAirPurgeProps> = (({ mode,onCountdownTerminate,activeButton,interrompre }) => {
    const [seconds, setSeconds] = useState(259);

    // Reset seconds when mode changes to Countdown
    useEffect(() => {
        if (mode === WarningAirPurgeMode.Countdown) {
            setSeconds(259);
        }
    }, [mode]);
    useEffect(() => {
        let interval: number | null = null;

        if (mode === WarningAirPurgeMode.Countdown && seconds > 0 && !interrompre) {
            interval = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds - 1);
            }, 20);
        } else if (interval) {
            clearInterval(interval);
        }
        if (mode === WarningAirPurgeMode.Countdown && seconds === 0) {
            onCountdownTerminate?.();
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [mode, seconds, interrompre]);

    return (
        <>
            <div className={mode === WarningAirPurgeMode.ValidationScreen ? "absolute" : "hidden"}>
                <div>

                <ValidationScreenPAC />
                </div>
            </div>
            <div className={mode === WarningAirPurgeMode.AirPurgeMessage ? "absolute h-full p-2" : "hidden"}>
                <WarningAirPurgeMessage activeButton={activeButton?? ''} />
            </div>
            <div className={mode === WarningAirPurgeMode.Countdown &&interrompre===true ? "absolute h-full p-2" : "hidden"}>
                <WarningAirPurgeInterompre />
            </div>
            <div className="h-full p-2 ">
                <div className=" h-1/5 flex flex-row  text-white ">
                    <div  className="bg-[#5a5e66]  w-1/2  flex flex-row items-center mr-1 ">
                        <div className="  text-xs font-extralight p-2">
                            <BsExclamationTriangleFill className="inline rounded text-[#fab237] p-1 w-8 h-8 " />
                            {/* <AiOutlineCheck className="inline rounded  bg-[#63AE65] p-1 w-8 h-8 mr-4" /> */}
                        </div>
                        <div>
                        {mode === WarningAirPurgeMode.Countdown ? (
                            <div className="basis-1/2">
                                <span>{seconds} <br/> sec</span>
                            </div>
                        ) : (
                            <div className="basis-1/2">
                                <span>...</span>
                            </div>
                        )}
                    </div>
                    </div>
                    <div  className="bg-[#16181a] w-1/2  text-xs font-extralight p-2 flex flex-row  items-center">
                            <div className="basis-1/2">
                                <img
                                className=' w-7 inline mr-2'
                                src="images/pressure.svg"
                                alt=''
                                /> 
                            </div>
                            <div className="basis-1/2">
                                <span className="text-xl">1.5</span>  <br /> bar
                            </div>
                    </div>
                </div>
                <div className=" h-2/5 bg-[#16181a] flex items-center justify-center relative mt-1 ">
                    <img className='absolute left-2 top-2 w-2' src={`images/robinet-active.svg`} alt='' />
                    <img className=' w-12' src={`images/Goutte.svg`} alt='' /> 
                </div>
                <div className=" h-2/5 flex flex-row  text-white mt-1">
                    
                    <div className={`  bg-[#16181a] w-1/2  flex flex-row items-center justify-center mr-1 relative`}>
                        <div className='absolute left-2 top-2 tet-xs text-white' >1</div>
                        <img className=' w-10' src={`images/Cercle.svg`} alt='' /> 
                    </div>
                    <div className={` bg-[#16181a]  w-1/2  flex flex-row items-center justify-center relative`}>
                        <div className='absolute left-2 top-2 tet-xs text-white' >2</div>
                        <img className=' w-10' src={`images/Cercle.svg`} alt='' /> 
                    </div>
                </div>
            </div>
        </>

    )

})

export default WarningAirPurge;
