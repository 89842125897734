import { LuChevronsUpDown } from "react-icons/lu";
import { AiOutlineCheck } from "react-icons/ai";

const LanguageSelection: React.FC<{ language: string }> =({ language }) =>(
    <div className="bg-[#222526] mx-1 mt-8">
      <p className="text-left text-[10px] p-2 text-[#C3CBD9] font-lato">Langue</p>
      <div className="text-[12px]  mb-8">
        <LuChevronsUpDown className="inline m-1 text-lg text-[#c3cbd9]" />
        <div className="bg-[#c3cbd9] text-black cursor-pointer text-right inline-block mb-2 w-48 font-exo2">
          {language}
          <AiOutlineCheck className="inline m-2" />
        </div>
      </div>
    </div>
  );
  

export default LanguageSelection;