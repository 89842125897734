import React, { ReactNode } from 'react';

type MenuColumnProps = {
  title: string;
  state: 1 | 2 ;
  icon?: ReactNode;
};

const MenuColumn: React.FC<MenuColumnProps> = ({ title,icon, state }) => (
    <div className='mb-1 mx-1'>
    {state===1 ? (
    <div className="flex justify-between items-center  mt-1 bg-[#C3CBD9]">   
        <div className="text-[10px] px-3 py-3 w-full text-center text-[#222526] ">
            <span className='mr-2'>
                {icon} 
            </span>
            {title}
            </div>
    </div>
    ) :(
    <div className="flex justify-between items-center  mt-1 bg-[#45474D]">   
        <div className="text-[10px] px-3 py-3 w-full text-center text-[#C3CBD9] ">{title}</div>
    </div>
    )}
    </div>
);

export default MenuColumn;
