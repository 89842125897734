export function switchToNextElement(
    elementsArray: string[], 
    currentElement: string, 
    setElementFunction: React.Dispatch<React.SetStateAction<string>>
    ) {
    const currentIndex = elementsArray.indexOf(currentElement);
    const nextIndex = (currentIndex + 1) % elementsArray.length;
    setElementFunction(elementsArray[nextIndex]);
}
export function switchToPreviousElement(
    elementsArray: string[], 
    currentElement: string, 
    setElementFunction: React.Dispatch<React.SetStateAction<string>>
    ) {
    const currentIndex = elementsArray.indexOf(currentElement);
    const prevIndex = (currentIndex - 1 + elementsArray.length) % elementsArray.length;
    setElementFunction(elementsArray[prevIndex]);
}