import React, { ReactNode } from 'react';

interface AppLayoutProps {
    children: ReactNode;
  }
  
const AppLayout:React.FC<AppLayoutProps> = ({ children }) => {
  document.body.style.touchAction = ""
  return (
    <div className="">
      <div className="flex flex-col items-center">
      <img
        src="images/atlantic-france-logo-vector.svg"
        alt="Atlantic logo"
        className="mx-auto my-4 w-28"
      />
    </div>
      <hr className=" border-orange-600 border-2 " />
      <div className="text-center">{children}</div>
    </div>
  );
};

export default AppLayout;
